import Mock from 'mockjs'

// 请求形式与返回数据的定义
export default Mock.mock('/local/auto/api/select_air', 'get', {
  stat: 'OK',
  message: '查询成功！',
  you: 7, // 本月空气质量为优的天数
  liang: 3, // 本月空气质量为良的天数
  qingdu: 6, // 本月空气质量为轻度污染的天数
  zhongdu: 6, // 本月空气质量为中度污染的天数
  ZhongDu: 4, // 本月空气质量为重度污染的天数
  yanzhong: 4, // 本月空气质量为严重污染的天数
  PM25: [
    {
      time: '2022-03-01T07:40:32.000Z',
      PM: 23
    },
    {
      time: '2022-03-02T07:40:32.000Z',
      PM: 12
    },
    {
      time: '2022-03-03T07:40:32.000Z',
      PM: 4
    },
    {
      time: '2022-03-04T07:40:32.000Z',
      PM: 23
    },
    {
      time: '2022-03-05T07:40:32.000Z',
      PM: 26
    },
    {
      time: '2022-03-06T07:40:32.000Z',
      PM: 23
    },
    {
      time: '2022-03-07T07:40:32.000Z',
      PM: 21
    },
    {
      time: '2022-03-08T07:40:32.000Z',
      PM: 13
    },
    {
      time: '2022-03-09T07:40:32.000Z',
      PM: 43
    },
    {
      time: '2022-03-10T07:40:32.000Z',
      PM: 25
    },
    {
      time: '2022-03-11T07:40:32.000Z',
      PM: 12
    },
    {
      time: '2022-03-12T07:40:32.000Z',
      PM: 15
    },
    {
      time: '2022-03-13T07:40:32.000Z',
      PM: 25
    },
    {
      time: '2022-03-14T07:40:32.000Z',
      PM: 16
    },
    {
      time: '2022-03-15T07:40:32.000Z',
      PM: 18
    },
    {
      time: '2022-03-16T07:40:32.000Z',
      PM: 23
    },
    {
      time: '2022-03-17T07:40:32.000Z',
      PM: 21
    },
    {
      time: '2022-03-18T07:40:32.000Z',
      PM: 13
    },
    {
      time: '2022-03-19T07:40:32.000Z',
      PM: 43
    },
    {
      time: '2022-03-20T07:40:32.000Z',
      PM: 24
    },
    {
      time: '2022-03-21T07:40:32.000Z',
      PM: 26
    },
    {
      time: '2022-03-22T07:40:32.000Z',
      PM: 28
    },
    {
      time: '2022-03-23T07:40:32.000Z',
      PM: 19
    },
    {
      time: '2022-03-24T07:40:32.000Z',
      PM: 17
    },
    {
      time: '2022-03-25T07:40:32.000Z',
      PM: 27
    },
    {
      time: '2022-03-26T07:40:32.000Z',
      PM: 23
    },
    {
      time: '2022-03-27T07:40:32.000Z',
      PM: 28
    },
    {
      time: '2022-03-28T07:40:32.000Z',
      PM: 13
    },
    {
      time: '2022-03-29T07:40:32.000Z',
      PM: 16
    },
    {
      time: '2022-03-30T07:40:32.000Z',
      PM: 7
    }
    // 本月PM2.5每日数据，共计30到31条数据
  ],
  PM10: [
    {
      time: '2022-03-01T07:40:32.000Z',
      PMten: 10
    },
    {
      time: '2022-03-02T07:40:32.000Z',
      PMten: 15
    },
    {
      time: '2022-03-03T07:40:32.000Z',
      PMten: 17
    },
    {
      time: '2022-03-04T07:40:32.000Z',
      PMten: 19
    },
    {
      time: '2022-03-05T07:40:32.000Z',
      PMten: 13
    },
    {
      time: '2022-03-06T07:40:32.000Z',
      PMten: 19
    },
    {
      time: '2022-03-07T07:40:32.000Z',
      PMten: 17
    },
    {
      time: '2022-03-08T07:40:32.000Z',
      PMten: 10
    },
    {
      time: '2022-03-09T07:40:32.000Z',
      PMten: 12
    },
    {
      time: '2022-03-10T07:40:32.000Z',
      PMten: 25
    },
    {
      time: '2022-03-11T07:40:32.000Z',
      PMten: 26
    },
    {
      time: '2022-03-12T07:40:32.000Z',
      PMten: 27
    },
    {
      time: '2022-03-13T07:40:32.000Z',
      PMten: 27
    },
    {
      time: '2022-03-14T07:40:32.000Z',
      PMten: 18
    },
    {
      time: '2022-03-15T07:40:32.000Z',
      PMten: 18
    },
    {
      time: '2022-03-16T07:40:32.000Z',
      PMten: 8
    },
    {
      time: '2022-03-17T07:40:32.000Z',
      PMten: 28
    },
    {
      time: '2022-03-18T07:40:32.000Z',
      PMten: 25
    },
    {
      time: '2022-03-19T07:40:32.000Z',
      PMten: 12
    },
    {
      time: '2022-03-20T07:40:32.000Z',
      PMten: 25
    },
    {
      time: '2022-03-21T07:40:32.000Z',
      PMten: 27
    },
    {
      time: '2022-03-22T07:40:32.000Z',
      PMten: 24
    },
    {
      time: '2022-03-23T07:40:32.000Z',
      PMten: 17
    },
    {
      time: '2022-03-24T07:40:32.000Z',
      PMten: 29
    },
    {
      time: '2022-03-25T07:40:32.000Z',
      PMten: 20
    },
    {
      time: '2022-03-26T07:40:32.000Z',
      PMten: 19
    },
    {
      time: '2022-03-27T07:40:32.000Z',
      PMten: 14
    },
    {
      time: '2022-03-28T07:40:32.000Z',
      PMten: 10
    },
    {
      time: '2022-03-29T07:40:32.000Z',
      PMten: 21
    },
    {
      time: '2022-03-30T07:40:32.000Z',
      PMten: 25
    }
  ]
  // 本月PM10每日数据，共计30到31条数据
})
