// 摄像头控制码
export enum controlCode {
  /**
   * 停止
   */
  STOP = 0,
  /**
   * 左上方
   */
  UPPER_LEFT = 1,
  /**
   * 向上
   */
  UPPER = 2,
  /**
   * 右上方
   */
  UPPER_RIGHT = 3,
  /**
   * 向左
   */
  LEFT = 4,
  /**
   * 向右
   */
  RIGHT = 6,
  /**
   * 左下方
   */
  LOWER_LEFT = 7,
  /**
   * 向下
   */
  LOWER = 8,
  /**
   * 右下方
   */
  LOWER_RIGHT = 9,
  /**
   * 变焦-缩小
   */
  ZOOM_IN = 10,
  /**
   * 变焦-放大
   */
  ZOOM_OUT = 11,
}

// 实时预览动作
export enum action {
  /**
   * 开始
   */
  START = 'start',
  /**
   * 停止
   */
  STOP = 'stop'
}

// 推流模式
export enum streamMode {
  /**
   * udp
   */
  UDP = 0,
  /**
   * TCP-被动
   */
  TCP_PASSIVITY = 1,
  /**
   * TCP-主动
   */
  TCP_INITIATIVE = 2
}

// // 摄像机开关
// export enum controlWord{
//   /**
//    * 打开
//    */
//   OPEN = 1,
//   /**
//    * 关闭
//    */
//   CLOSE = 0
// }
