import { createContext } from 'react'

import { ICameraStore } from '@/model/cameraType'
import { getStorage } from '@/utils'
import { centerCamera } from '@/utils/constant'

export const globalInfo = {
  userName: getStorage('username') || '',
  phone: getStorage('phone') || '',
  camera: centerCamera,
  setUserName (value: string) {
    globalInfo.userName = value
  },
  setPhone (value: string) {
    globalInfo.phone = value
  },
  setCamera (value: ICameraStore) {
    globalInfo.camera = value
  }
}

export const GlobalInfoContext = createContext(globalInfo)
