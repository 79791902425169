import { ECOption } from '@/types'
import { blueColor, lineColor } from '@/utils/charts'

export const airLineOption: ECOption = {
  title: {
    text: '今日空气质量/一月空气质量',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  color: [
    '#64af89',
    '#e2c462',
    '#e1a050',
    '#d86b5f',
    '#925eba',
    '#7d3948'
  ],
  grid: {
    top: 25,
    bottom: 0
    // height: 10
  },
  yAxis: [
    {
      type: 'category',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false,
        alignWithLabel: true
      },
      axisLabel: {
        show: false
      },
      splitArea: {
        show: false
      },
      splitLine: {
        show: false
      }
    }
  ],
  xAxis: [
    {
      type: 'value',
      position: 'top',
      offset: -30,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false,
        alignWithLabel: true
      },
      axisLabel: {
        show: true,
        color: '#aaa'
      },
      splitArea: {
        show: false
      },
      splitLine: {
        show: false
      }
    }
  ],
  series: [{
    data: [50],
    name: '优',
    stack: 'one',
    barMaxWidth: 10,
    type: 'bar',
    markPoint: {
      // name: 'point',
      symbol: 'pin',
      symbolSize: [30, 30],
      symbolOffset: [0, 30],
      label: {
        show: true,
        position: 'insideTop'
      },
      itemStyle: {
        color: 'red'
      },
      data: [
        {
          name: '质量',
          xAxis: 15,
          y: 15,
          value: '15'
        }
      ]
    }
  },
  {
    data: [
      50
    ],
    name: '良',
    stack: 'one',
    type: 'bar'
  },
  {
    data: [
      50
    ],
    name: '轻度',
    stack: 'one',
    type: 'bar'
  },
  {
    data: [
      50
    ],
    name: '中度',
    stack: 'one',
    type: 'bar'
  },
  {
    data: [
      50
    ],
    name: '重度',
    stack: 'one',
    type: 'bar'
  },
  {
    data: [
      50
    ],
    name: '严重',
    stack: 'one',
    type: 'bar'
  }
  ]
}

// 趋势图的参数结构
export interface IPmxParams {
  date: string[]
  pm25: number[]
  pm10: number[]
}

export const pmOption = (params: IPmxParams): ECOption => ({
  title: {
    text: '月PM2.5/PM10趋势',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '15%',
    left: '10%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: true
    },
    splitArea: {
      show: false
      // color: '#f00',
      // lineStyle: {
      //   color: '#f00'
      // }
    },
    axisLabel: {
      interval: 8,
      color: '#BCDCF0',
      fontSize: 10
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date
  },

  yAxis: {
    type: 'value',
    min: 0,
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: true
    },
    axisLabel: {
      show: true,
      margin: 10,
      color: '#d1e6eb'
    },
    axisTick: {
      show: false
    }
  },
  series: [
    {
      name: 'PM2.5',
      type: 'line',
      smooth: true, // 是否平滑
      lineStyle: {
        color: '#00b3f4',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00b3f4'
      },
      // 去除点标记
      symbolSize: 0,
      // 鼠标放上去还是要有颜色的
      itemStyle: {
        color: '#00b3f4'
      },
      // 设置渐变色
      areaStyle: {
        color: lineColor,
        shadowColor: 'rgba(0,179,244, 0.9)',
        shadowBlur: 20
      },
      data: params.pm25
    },
    {
      name: 'PM10',
      type: 'line',
      smooth: true, // 是否平滑
      // 阴影
      lineStyle: {
        color: '#00ca95',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00ca95'
      },
      // 去除点标记
      symbolSize: 0,
      itemStyle: {
        color: '#00ca95'
      },
      // 设置渐变色
      areaStyle: {
        color: blueColor,
        shadowColor: 'rgba(0,202,149, 0.9)',
        shadowBlur: 20
      },
      data: params.pm10
    }
  ]
})

// 空气质量饼图
export interface IAirpieparams {
  AQI1: number
  AQI2: number
  AQI3: number
}

export const airPieOption = (params: IAirpieparams): ECOption => ({
  title: {
    text: '一月空气质量',
    show: false
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 14
    }
  },
  legend: {
    orient: 'vertical',
    right: 'right',
    itemWidth: 10,
    itemHeight: 8,
    itemGap: 2,
    textStyle: {
      color: '#fff',
      fontSize: 10
    }
  },
  series: [
    {
      name: '一月空气质量分布',
      type: 'pie',
      radius: '80%',
      center: ['25%', '50%'],
      data: [
        {
          value: params.AQI1,
          name: '优',
          label: {
            show: false
          },
          itemStyle: {
            color: '#64af89'
          }
        },
        {
          value: params.AQI2,
          name: '良',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e2c462'
          }
        },
        {
          value: params.AQI3,
          name: '轻度污染',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e1a050'
          }
        }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
})
