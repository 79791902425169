import Charts from '@/components/Common/Charts'

import { pmOption, IPmxParams } from './options'

import styles from './style.module.scss'

interface IProps {
  data: IPmxParams
}

export default function PmTrend (props: IProps) {
  return (
    <>
      <div className={styles.pm_chart}>
        <Charts
          key='pm2.5_pm10'
          style={{
            width: '100%',
            height: '99%'
          }}
          option={pmOption(props.data)}
        />
      </div>
    </>
  )
}
