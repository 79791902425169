import Charts from '@/components/Common/Charts'

import { IonicOption, IDataPrams } from './options_temp'

import styles from './style.module.scss'

interface IProps {
  data1: IDataPrams
}

export default function Ionic (props: IProps) {
  return (
    <>
      <div className={styles.soil_chart}>
        <Charts
          key='bigdata'
          style={{
            width: '100%',
            height: '200%'
          }}
          option={IonicOption(props.data1)}
        />
      </div>
    </>
  )
}
