import axios from 'axios'

// import { BaseRes } from '@/model/baseType'
// import { action, controlCode, streamMode } from '@/model/enum'
// import { ICatalog } from '@/model/cameraType'
import { IToken } from '@/model/cameraType'

// @:  token获取
export async function getToken() {
  const data = await axios.get<IToken>('http://121.5.157.30:3000/api/accessToken')
  return data.data
}
