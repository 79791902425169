import Charts from '@/components/Common/Charts'

import { airPieOption, airPieOption_nan, IDataPrams } from './options_temp'

import styles from './style.module.scss'

interface IProps {
  data: IDataPrams
}

export default function AirQualityPie (props: IProps) {
  return (
    <>
      <div className={styles.air_piechart}>
        <Charts
          key='airQualityPie'
          style={{
            width: '80%',
            height: '80%'
          }}
          option={airPieOption(props.data)}
        />
        <Charts
          key='airQualityPie'
          style={{
            width: '80%',
            height: '80%'
          }}
          option={airPieOption_nan(props.data)}
        />
      </div>
      {/* <div className={styles.air_piechart}>
        <Charts
          key='airQualityPie'
          style={{
            width: '100%',
            height: '100%'
          }}
          option={airPieOption_nan(props.data)}
        />
      </div> */}
    </>
  )
}
