import { ECOption } from '@/types'
import { lineColor, blueColor, yellowColor } from '@/utils/charts'

// 趋势图参数数据结构
export interface ISoilPrams {
  /**
   * 日期
   */
  date: string[]
  /**
   * 氮
   */
  nitrogen: number[]
  /**
   * 磷
   */
  phosphorus: number[]
  /**
   * 钾
   */
  potassium: number[]
  /**
  * PH
  */
  PH: number[]
  /**
  *湿度
  */
  moisture: number[]
  /*
  *温度
  */
  temperature: number[]
}

export const IonicOption = (params: ISoilPrams): ECOption => ({
  title: {
    text: '月土壤趋势',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '15%'
  },
  xAxis: {
    type: 'category',
    axisLine: {
      show: true
    },
    splitArea: {
      show: false
    },
    axisLabel: {
      interval: 8,
      color: '#BCDCF0',
      fontSize: 10
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date
  },

  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLine: {
      show: true
    },
    axisLabel: {
      color: '#d1e6eb'
    },
    axisTick: {
      show: false
    }
  },
  series: [
    {
      name: '温度',
      type: 'line',
      smooth: true, // 是否平滑
      lineStyle: {
        color: '#00b3f4',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00b3f4'
      },
      // 去除点标记
      symbolSize: 0,
      // 鼠标放上去还是要有颜色的
      itemStyle: {
        color: '#00b3f4'
      },
      // 设置渐变色
      areaStyle: {
        color: lineColor,
        shadowColor: 'rgba(0,179,244, 0.9)',
        shadowBlur: 20
      },
      data: params.nitrogen
    }, {
      name: '湿度',
      type: 'line',
      smooth: true, // 是否平滑
      // 阴影
      lineStyle: {
        color: '#00ca95',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00ca95'
      },
      // 去除点标记
      symbolSize: 0,
      itemStyle: {
        color: '#00ca95'
      },
      // 设置渐变色
      areaStyle: {
        color: blueColor,
        shadowColor: 'rgba(0,202,149, 0.9)',
        shadowBlur: 20
      },
      data: params.phosphorus
    }, {
      name: '酸碱度',
      type: 'line',
      smooth: true, // 是否平滑
      // 阴影
      lineStyle: {
        color: '#ffde32',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00ca95'
      },
      // 去除点标记
      symbolSize: 0,
      itemStyle: {
        color: '#00ca95'
      },
      // 设置渐变色
      areaStyle: {
        color: yellowColor,
        shadowColor: 'rgba(0,202,149, 0.9)',
        shadowBlur: 20
      },
      data: params.potassium
    }
  ]
})
