import Charts from '@/components/Common/Charts'

import { temOption, ITem } from './options'

import styles from './style.module.scss'

interface IProps {
  data: ITem
}

export default function Temperature (props: IProps) {
  return (
    <>
      <div className={styles.tem_chart}>
        <Charts
          key='temperature'
          style={{
            width: '100%',
            height: '100%'
          }}
          option={temOption(props.data)}
        />
      </div>
    </>
  )
}
