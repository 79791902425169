import { controlCode } from '@/model/enum'
import { WeatherParams } from '@/model/baseType'
import { IPanel } from '@/model/componentType'
import { ICameraStore } from '@/model/cameraType'

// 请求天气预报结构所传的参数
// 测试次数没了需要充值，如果天气7天部分没有显示，则是因为这
export const WeaParams: WeatherParams = {
  appid: '37144812',
  appsecret: 'ASfRZJ7a',
  version: 'v9',
  cityid: '101200906',
  unescape: 1,
  vue: '1'
}

// export const WeaParams: WeatherParams = {
//   appid: '15863267',
//   appsecret: 'He39CV1F',
//   version: 'v1',
//   cityid: '101200906',
//   unescape: 1,
//   vue: '1'
// }

// 摄像头控制折叠面板循环数据
export const ControColl: IPanel[] = [{
  key: 'move',
  header: '控制',
  row: [{
    key: 'move_1',
    col: [{
      button: {
        iconType: 'icon-jiantou_zuoshang',
        controCode: controlCode.UPPER_LEFT
      }
    }, {
      button: {
        iconType: 'icon-jiantou_xiangshang',
        controCode: controlCode.UPPER
      }
    }, {
      button: {
        iconType: 'icon-jiantou_youshang',
        controCode: controlCode.UPPER_RIGHT
      }
    }]
  }, {
    key: 'move_2',
    col: [{
      button: {
        iconType: 'icon-jiantou_xiangzuo',
        controCode: controlCode.LEFT
      }
    }, {
      button: {
        iconType: 'icon-jiantou_xiangyou',
        controCode: controlCode.RIGHT
      }
    }]
  }, {
    key: 'move_3',
    col: [{
      button: {
        iconType: 'icon-jiantou_zuoxia',
        controCode: controlCode.LOWER_LEFT
      }
    }, {
      button: {
        iconType: 'icon-jiantou_xiangxia',
        controCode: controlCode.LOWER
      }
    }, {
      button: {
        iconType: 'icon-jiantou_youxia',
        controCode: controlCode.LOWER_RIGHT
      }
    }]
  }]
}, {
  key: 'zoom',
  header: '变焦',
  row: [{
    key: 'zoom_1',
    col: [{
      button: {
        iconType: 'icon-jiahao',
        controCode: controlCode.ZOOM_OUT
      }
    }, {
      button: {
        iconType: 'icon-jianhao',
        controCode: controlCode.ZOOM_IN
      }
    }]
  }]
}]

// 流媒体服务器相关设置
export const mediaConfig = {
  /**
   * 流媒体服务器地址
   */
  mediaHost: '121.5.157.30',
  /**
   * 摄像头推流端口号
   */
  mediaPort: 10000,
  /**
   * 流媒体服务器http端口号
   */
  httpPort: '9094',
  /**
   * 流应用名
   */
  appName: 'rtp',
  /**
   * flv的后缀
   */
  flvPostFix: '.live.flv'
}

// 中心摄像头信息
export const centerCamera: ICameraStore = {
  name: '中心摄像头',
  src: 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/J99041804/1.live&accessToken=at.dohipuw18ql9n8bzdyi0tcbvcx3f0wjw-8oit83kg3l-1t7qfdz-6ihg4aoxy&id=ysopen'
}

// 摄像头1信息
export const camera1: ICameraStore = {
  name: 'camera1',
  src: 'https://open.ys7.com/jssdk/theme.html?url=ezopen://open.ys7.com/J99041804/1.live&accessToken=at.cf3t4lgh27sl8p9r7tik773fd30id2h8-67wku9fvvt-11pb6lx-qw9yvcbbd&id=ysopen'
}

// // 摄像头2信息
// export const camera2: ICameraStore = {
//   name: 'camera2',
//   channelId: '42000000401320000003',
//   deviceId: '42000000401320000003'
// }

// // 摄像头3信息
// export const camera3: ICameraStore = {
//   name: 'camera3',
//   channelId: '42000000401320000004',
//   deviceId: '42000000401320000004'
// }

// // 摄像头4信息
// export const camera4: ICameraStore = {
//   name: 'camera4',
//   channelId: '42000000401320000005',
//   deviceId: '42000000401320000005'
// }
