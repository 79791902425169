import { encode, decode } from '@/utils/crypto'
import { mediaConfig } from '@/utils/constant'

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time: Date, fmt: string) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o: any = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      )
    }
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}

/**
 * @: 补全整数
 * @param {string} num
 * @param {number} m
 * @return {*}
 */
export function prefixInteger(num: string, m: number) {
  return (Array(m).join('0') + num).slice(-m)
}

/**
 * @: 提取获取的风速等级只要前面的数字部分
 * @param {string} text
 * @return {*}
 */
export function formatWind (text: string) {
  let value = ''
  if (text.includes('级转')) {
    value = text.split('级转')[0]
  } else {
    value = text.slice(0, -1)
  }
  return value
}

// 能见度的数据结构
export interface IVisi {
  /**
   * 数值
   */
  number: string
  /**
   * 单位
   */
  unit: string
}

/**
 * @: 提取能见度的数值和单位
 * @param {string} text
 * @return {*}
 */
export function formatVisibility (text: string) {
  const obj: IVisi = {
    number: text,
    unit: text
  }
  obj.number = text.replace(/[^\d]/g, '')
  obj.unit = text.replace(/[^a-zA-Z]/g, '')
  return obj
}

/**
 * @: 保存值到localStorage
 * @param {string} key
 * @param {string} value
 * @param {boolean} crypto
 * @return {*}
 */
export function setStorage (key: string, value: string, crypto?: boolean) {
  if (crypto) {
    value = encode(value)
  }
  window.localStorage.setItem(key, value)
}

/**
 * @: 从localStorage取值
 * @param {string} key
 * @param {boolean} crypto
 * @return {*}
 */
export function getStorage (key: string, crypto?: boolean) {
  let value = window.localStorage.getItem(key)
  if (crypto && value) {
    value = decode(value)
  }
  return value
}

/**
 * @: 从localStorage删除值
 * @param {string} key
 * @return {*}
 */
export function delStorage (key: string) {
  window.localStorage.removeItem(key)
}

/**
 * @: 根据ssrc返回flv的地址
 * @param {string} streamId
 * @return {*}
 */
export function getVideoUrl (streamId: string) {
  const dec = parseInt(streamId)
  const ssrc = dec.toString(16).toUpperCase()
  return `http://${mediaConfig.mediaHost}:${mediaConfig.httpPort}/${mediaConfig.appName}/${ssrc.padStart(8, '0')}${mediaConfig.flvPostFix}`
}
