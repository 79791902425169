import { ECOption } from '@/types'
import { mapColorList } from '@/utils/charts'

import location1 from '@assets/images/map/location1.png'
import location2 from '@assets/images/map/location2.png'
// import location3 from '@assets/images/map/location3.png'
import border from '@assets/images/map/border.png'

// 传入的数据类型
export interface IMap {
  name: string,
  value: number[],
  datas: number,
  img: string
}

const mapData: IMap[] = [{
  name: 'md1监控',
  value: [435.2941176470588, 514.1945701357465],
  datas: 1354,
  img: 'image://' + location1
},
{
  name: 'md2监控(小)',
  value: [668.7782805429864, 272.56561085972845],
  datas: 1402,
  img: 'image://' + location2
}]

// const mapData: IMap[] = [{
//   name: '蔡甸区',
//   value: [113.96512, 30.451884],
//   datas: 1354,
//   img: 'image://' + location1
// },
// {
//   name: '江夏区',
//   value: [114.360325, 30.243389],
//   datas: 1402,
//   img: 'image://' + location2
// },
// {
//   name: '新洲区',
//   value: [114.752673, 30.798095],
//   datas: 2468,
//   img: 'image://' + location1
// },
// {
//   name: '黄陂区',
//   value: [114.355245, 30.978113],
//   datas: 768,
//   img: 'image://' + location3
// },
// {
//   name: '东西湖区',
//   value: [114.076902, 30.690527],
//   datas: 768,
//   img: 'image://' + location2
// }]

export const mapOption: ECOption = {
  title: {
    show: false,
    text: '武汉市地图',
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  legend: {
    show: false
  },
  geo: {
    map: 'wuhan',
    aspectScale: 0.75,
    layoutCenter: ['50%', '50.5%'],
    layoutSize: '100%'
    // itemStyle: {
    //   borderColor: 'rgba(255,209,163, .5)', // 区域边框颜色
    //   areaColor: 'rgba(73,86,166,.1)', // 区域颜色
    //   borderWidth: 1, // 区域边框宽度
    //   shadowBlur: 5,
    //   shadowColor: 'rgba(107,91,237,.7)'
    // },
    // emphasis: {
    //   itemStyle: {
    //     borderColor: '#FFD1A3',
    //     areaColor: 'rgba(102,105,240,.3)',
    //     borderWidth: 1,
    //     shadowBlur: 5,
    //     shadowColor: 'rgba(135,138,255,.5)'
    //   },
    //   label: {
    //     show: false
    //   }
    // }
  },
  // geo: {
  //   map: 'wuhan',
  //   aspectScale: 0.75,
  //   layoutCenter: ['50%', '50.5%'],
  //   layoutSize: '100%',
  //   itemStyle: {
  //     borderColor: 'rgba(255,209,163, .5)', // 区域边框颜色
  //     areaColor: 'rgba(73,86,166,.1)', // 区域颜色
  //     borderWidth: 1, // 区域边框宽度
  //     shadowBlur: 5,
  //     shadowColor: 'rgba(107,91,237,.7)'
  //   },
  //   emphasis: {
  //     itemStyle: {
  //       borderColor: '#FFD1A3',
  //       areaColor: 'rgba(102,105,240,.3)',
  //       borderWidth: 1,
  //       shadowBlur: 5,
  //       shadowColor: 'rgba(135,138,255,.5)'
  //     },
  //     label: {
  //       show: false
  //     }
  //   }
  // },
  series: [{
    name: 'Ripple',
    tooltip: {
      show: false
    },
    type: 'effectScatter',
    coordinateSystem: 'geo',
    rippleEffect: {
      scale: 10,
      brushType: 'stroke'
    },
    showEffectOn: 'render',
    itemStyle: {
      shadowColor: '#0ff',
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      color: function (params: any) {
        return mapColorList[params.dataIndex]
      }
    },
    label: {
      color: '#fff'
    },
    symbol: 'circle',
    symbolSize: [10, 5],
    data: mapData,
    zlevel: 1
  }, {
    name: 'Coordinate',
    type: 'scatter',
    coordinateSystem: 'geo',
    itemStyle: {
      color: '#f00'
    },
    symbol: function (_, params: any) {
      return params.data.img
    },
    symbolSize: [32, 41],
    symbolOffset: [0, -20],
    z: 9999,
    data: mapData
  }, {
    name: 'InfoBox',
    type: 'scatter',
    coordinateSystem: 'geo',
    label: {
      show: true,
      formatter: function (params: any) {
        const name = params.name
        // const value = params.data.datas
        // const text = `{fline|${value}}\n{tline|${name}}`
        const text = `{fline|${name}}`
        return text
      },
      color: '#fff',
      rich: {
        fline: {
          padding: [0, 25],
          color: '#fff',
          textShadowColor: '#030615',
          textShadowBlur: 0,
          textShadowOffsetX: 1,
          textShadowOffsetY: 1,
          fontSize: 14,
          fontWeight: 400
        },
        tline: {
          padding: [0, 27],
          color: '#ABF8FF',
          fontSize: 12
        }
      }
    },
    itemStyle: {
      color: '#00FFF6'
    },
    symbol: 'image://' + border,
    symbolSize: [100, 50],
    symbolOffset: [0, -60],
    z: 999,
    data: mapData
  }]
}
