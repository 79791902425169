import { ECOption } from '@/types'
import { lineColor, blueColor } from '@/utils/charts'

// 趋势图参数数据结构
export interface IDataPrams {
  /**
   * 日期
   */
  date1: string[]
    /**
   * 日期2
   */
  date2: string[]
  /**
   * 马岩墩平均温度
   */
  avg_mb3: number
  /**
   * 楠木桥平均温度
   */
  avg_nb2: number
  /**
  * 马岩墩37度以上天气天数
  */
  ma_morethan_37: number
  /**
  * 马岩墩37度以下，33度以上天气天数
  */
  ma_between_33and37: number
  /**
  * 马岩墩33度以下，10度以上天气天数
  */
  ma_between_10and33: number
  /**
  * 马岩墩10度以下，3度以上天气天数
  */
  ma_between_3and10: number
  /**
  * 马岩墩3度以下天气天数
  */
  ma_lessthan_3: number
  /**
  * 楠木桥37度以上天气天数
  */
  nan_morethan_37: number
  /**
  * 楠木桥37度以下，33度以上天气天数
  */
  nan_between_33and37: number
  /**
  * 楠木桥33度以下，10度以上天气天数
  */
  nan_between_10and33: number
  /**
  * 楠木桥10度以下，3度以上天气天数
  */
  nan_between_3and10: number
  /**
  * 楠木桥3度以下天气天数
  */
  nan_lessthan_3: number
  /**
   * 楠木桥温度
   */
  nb2_ambientTemperature: number[]
  /**
   * 马岩墩温度
   */
  mb3_ambientTemperature: number[]
}

export const IonicOption = (params: IDataPrams): ECOption => ({
  title: {
    text: '温度大数据',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '15%'
  },
  xAxis: [{
    type: 'category',
    axisLine: {
      show: true
    },
    splitArea: {
      show: false
    },
    axisLabel: {
      interval: 8,
      color: '#BCDCF0',
      fontSize: 10
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date2
  }, {
    // name: 'ma',
    type: 'category',
    offset: -5,
    position: 'top',
    axisLine: {
      show: false
    },
    splitArea: {
      show: false
    },
    axisLabel: {
      color: '#91CC75'
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date2
  }],
  yAxis: {
    type: 'value',
    splitLine: {
      show: false
    },
    axisLine: {
      show: true
    },
    axisLabel: {
      color: '#d1e6eb'
    },
    axisTick: {
      show: false
    }
  },
  dataZoom: [
    {
      show: true,
      start: 80,
      end: 100,
      height: '5%'
    },
    {
      type: 'inside',
      start: 94,
      end: 100
    }
    // {
    //   show: true,
    //   yAxisIndex: 0,
    //   filterMode: 'empty',
    //   width: 25,
    //   height: '68%',
    //   showDataShadow: false,
    //   left: '94%'
    // }
  ],
  series: [
    {
      name: '马岩墩温度',
      type: 'line',
      smooth: true, // 是否平滑
      lineStyle: {
        color: '#00b3f4',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00b3f4'
      },
      // 去除点标记
      symbolSize: 0,
      // 鼠标放上去还是要有颜色的
      itemStyle: {
        color: '#00b3f4'
      },
      emphasis: {
        itemStyle: {
          borderWidth: 2,
          borderColor: '#ff9900',
          color: 'yellow'
        }
      },
      // clickable: true,
      // onclick: (params: IDataPrams) => {
      // const dataIndex = params.mb3_ambientTemperature;
      // const date = params.date1
      // 生成跳转链接
      // const url = `https://example.com/${date}`
      // 执行页面跳转
      //   window.location.href = url
      // },
      // 设置渐变色
      areaStyle: {
        color: lineColor,
        shadowColor: 'rgba(0,179,244, 0.9)',
        shadowBlur: 20
      },
      markPoint: {
        data: [
          {
            type: 'max',
            symbol: 'circle',
            name: '最高温',
            symbolSize: 5,
            label: { show: false, fontSize: 12 },
            itemStyle: { color: '#df3515' }
          },
          {
            type: 'min',
            symbol: 'circle',
            name: '最低温',
            symbolSize: 5,
            label: { show: false, fontSize: 12 },
            itemStyle: { color: '#df3515' }
          }
        ]
      },
      data: params.mb3_ambientTemperature
    }, {
      name: '楠木桥温度',
      type: 'line',
      smooth: true, // 是否平滑
      // 阴影
      lineStyle: {
        color: '#00ca95',
        shadowColor: 'rgba(0, 0, 0, .3)',
        shadowBlur: 0,
        shadowOffsetY: 5,
        shadowOffsetX: 5
      },
      label: {
        show: false,
        position: 'top',
        color: '#00ca95'
      },
      // 去除点标记
      symbolSize: 0,
      itemStyle: {
        color: '#00ca95'
      },
      // 设置渐变色
      areaStyle: {
        color: blueColor,
        shadowColor: 'rgba(0,202,149, 0.9)',
        shadowBlur: 20
      },
      markLine: {
        silent: false,
        symbol: 'none',
        data: [{
          yAxis: 37,
          name: '高温预警',
          label: {
            position: 'insideEnd',
            formatter: '高温预警',
            fontSize: 9
          },
          lineStyle: {
            type: 'dashed',
            color: 'red'
          }
        }, {
          yAxis: 3,
          name: '低温预警',
          label: {
            position: 'insideEnd',
            formatter: '低温预警',
            fontSize: 9
          },
          lineStyle: {
            type: 'dashed',
            color: 'red'
          }
        }, {
          yAxis: params.avg_mb3,
          name: '马岩墩平均温度',
          label: {
            position: 'insideEnd',
            formatter: '马均温',
            fontSize: 9
          },
          lineStyle: {
            type: 'dashed',
            color: 'blue'
          }
        }, {
          yAxis: params.avg_nb2,
          name: '楠木桥平均温度',
          label: {
            position: 'insideEnd',
            formatter: '楠均温',
            fontSize: 9
          },
          lineStyle: {
            type: 'dashed',
            color: 'green'
          }
        }]
      },
      markPoint: {
        data: [
          {
            type: 'max',
            symbol: 'circle',
            name: '最高温',
            symbolSize: 5,
            label: { show: false, fontSize: 12 },
            itemStyle: { color: '#df3515' }
          },
          {
            type: 'min',
            symbol: 'circle',
            name: '最低温',
            symbolSize: 5,
            label: { show: false, fontSize: 12 },
            itemStyle: { color: '#df3515' }
          }
        ]
      },
      data: params.nb2_ambientTemperature
    }
  ]
})
// 马岩墩温度30日
export const airPieOption = (params: IDataPrams): ECOption => ({
  title: {
    text: '30天马岩墩',
    right: 1,
    bottom: 0.5,
    show: true,
    textStyle: {
      fontSize: 11,
      color: '#999999'
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 14
    }
  },
  legend: {
    orient: 'vertical',
    right: 'right',
    itemWidth: 10,
    itemHeight: 8,
    itemGap: 2,
    textStyle: {
      color: '#fff',
      fontSize: 10
    }
  },
  series: [
    {
      name: '30日气温分布比例',
      type: 'pie',
      radius: '80%',
      center: ['50%', '50%'],
      data: [
        {
          value: params.ma_lessthan_3,
          name: '低温（3°下）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#006699'
          }
        },
        {
          value: params.ma_between_3and10,
          name: '较低温（3°-10°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#64af89'
          }
        },
        {
          value: params.ma_between_10and33,
          name: '正常（10°-33°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e2c462'
          }
        },
        {
          value: params.ma_between_33and37,
          name: '较高温（33°-37°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e1a050'
          }
        },
        {
          value: params.ma_morethan_37,
          name: '高温（37°上）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#DE3E26'
          }
        }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
})
// 楠木桥温度30日
export const airPieOption_nan = (params: IDataPrams): ECOption => ({
  title: {
    text: '30天楠木桥',
    right: 1,
    bottom: 0.5,
    show: true,
    textStyle: {
      fontSize: 11,
      color: '#999999'
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)',
    textStyle: {
      fontSize: 14
    }
  },
  legend: {
    orient: 'vertical',
    right: 'right',
    itemWidth: 10,
    itemHeight: 8,
    itemGap: 2,
    textStyle: {
      color: '#fff',
      fontSize: 10
    }
  },
  series: [
    {
      name: '30日气温分布比例',
      type: 'pie',
      radius: '80%',
      center: ['50%', '50%'],
      data: [
        {
          value: params.nan_lessthan_3,
          name: '低温（3°下）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#006699'
          }
        },
        {
          value: params.nan_between_3and10,
          name: '较低温（3°-10°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#64af89'
          }
        },
        {
          value: params.nan_between_10and33,
          name: '正常（10°-33°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e2c462'
          }
        },
        {
          value: params.nan_between_33and37,
          name: '较高温（33°-37°）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#e1a050'
          }
        },
        {
          value: params.nan_morethan_37,
          name: '高温（37°上）',
          label: {
            show: false
          },
          itemStyle: {
            color: '#DE3E26'
          }
        }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
})
