import Charts from '@/components/Common/Charts'

import { temOption, IDataPrams2 } from './options_light'

import styles from './style.module.scss'

interface IProps {
  data2: IDataPrams2
}
export default function Temperature (props: IProps) {
  return (
      <div className={styles.tem_chart}>
        <Charts
          key='temperature'
          style={{
            width: '84%',
            height: '300%'
          }}
          option={temOption(props.data2)}
        />
      </div>
  )
}
