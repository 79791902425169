import { message } from 'antd'
import { BorderBox12 } from '@jiaminghi/data-view-react'
import { useState, useEffect, useRef } from 'react'

import BarometricPressure from './Charts/barometricPressure'
import WindDirection from './Charts/windDirection'
import WeatherForecat from './Charts/weatherForecat'
import Temperature from './Charts/temperature'
// import AirQualityLine from '../Meteorological/Charts/airQualityLine'
// import AirQualityPie from '../Meteorological/Charts/airQualityPie'
import PmTrend from '../Meteorological/Charts/pmTrend'
import { getWeather, getWea } from '@services/weather'
import { getAirTure } from '@/services/air'
import { IWeatherParams, ITem } from './Charts/options'
import { formatVisibility, IVisi } from '@/utils'
import { IPmxParams } from '../Meteorological/Charts/options'

import '@/assets/styles/icon.scss'
import styles from './style.module.scss'

export default function Weather () {
  // 原气象监测
  // const [airPie, setAirPie] = useState<IAirpieparams>({
  //   AQI1: 0,
  //   AQI2: 0,
  //   AQI3: 0,
  //   AQI4: 0,
  //   AQI5: 0,
  //   AQI6: 0
  // })
  const intervalRef = useRef<any>()
  const [pmx, setPmx] = useState<IPmxParams>({
    date: [''],
    pm25: [0],
    pm10: [0]
  })
  const [pm2, setPm2] = useState<number>(0)
  const [pm10, setPm10] = useState<number>(0)
  const [humidity, setHumidity] = useState<number>(0)
  const [anion, setAnion] = useState<number>(0)
  const [rainfall, setrainfall] = useState<number>(0)
  const [light, setlight] = useState<number>(0)
  const [windspeed, setwindspeed] = useState<number>(0)
  const [temperature, settemperature] = useState<number>(0)
  const temperature2 = toFix(temperature, 1)
  const [pressure, setPressure] = useState<number>(1013)
  const pressure2 = pressure * 10
  const [winddirection, setwinddirection] = useState<number>(0)
  function toFix(num1: number, num2: number) {
    if (typeof (num1) === 'undefined') {
      return num1
    } else {
      return Number(num1.toFixed(num2))
    }
  }
  async function getAirData () {
    // const data = await getAir()
    const data = await getAirTure()
    // const mockData = await getMockAir()
    const mockData = await getAirTure()
    if (mockData.status === 'OK') {
      // setAirPie({
      //   AQI1: mockData.you,
      //   AQI2: mockData.liang,
      //   AQI3: mockData.qingdu,
      //   AQI4: mockData.zhongdu,
      //   AQI5: mockData.ZhongDu,
      //   AQI6: mockData.yanzhong
      // })
      const date: string[] = []
      const pmTwo: number[] = []
      const pmTen: number[] = []
      mockData.PM25.forEach((value: { time: string | number | Date; PM: number }) => {
        const time = new Date(value.time)
        date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
        pmTwo.push(value.PM)
      })
      mockData.PM10.forEach((value: { PMten: number }) => {
        pmTen.push(value.PMten)
      })
      setPmx({
        date: date,
        pm25: pmTwo,
        pm10: pmTen
      })
    }
    if (data.status === 'OK') {
      setPm2(data.meteorological[0].pm25)
      setPm10(data.meteorological[0].pm10)
      setHumidity(data.meteorological[0].ambientTemperature)
      setAnion(data.meteorological[0].negativeOxygen)
      setrainfall(data.meteorological[0].rainfall)
      setlight(data.meteorological[0].lightIntensity)
      setwindspeed(data.meteorological[0].windSpeed)
      settemperature(data.meteorological[0].ambientTemperature)
      setPressure(data.meteorological[0].pressure)
      setwinddirection(data.meteorological[0].windDirection)
      // setAirPie({
      //   AQI1: data.you,
      //   AQI2: data.liang,
      //   AQI3: data.qingdu,
      //   AQI4: data.zhongdu,
      //   AQI5: data.ZhongDu,
      //   AQI6: data.yanzhong
      // })
      // const date: string[] = []
      // const pmTwo: number[] = []
      // const pmTen: number[] = []
      // data.PM25.forEach((value) => {
      //   const time = new Date(value.time)
      //   date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
      //   pmTwo.push(value.PM)
      // })
      // data.PM10.forEach((value) => {
      //   pmTen.push(value.PMten)
      // })
      // setPmx({
      //   date: date,
      //   pm2: pmTwo,
      //   pm10: pmTen
      // })
    } else {
      message.error('获取气象监测数据失败！')
    }
  }
  useEffect(() => {
    (async function handleData() {
      await getAirData()
    })()
  }, [])

  // 原气象态势
  const [weaIcon, setWeaIcon] = useState<string>('')
  // const [windLevel, setWindLevel] = useState<string>('')
  const [visibility, setVisibility] = useState<IVisi>({
    number: '',
    unit: ''
  })
  // const [tem, setTem] = useState<string>('')
  // const [angle, setAngle] = useState<number>(0)
  // const [pressure, setPressure] = useState<number>(1013)
  const [forecat, setForecat] = useState<IWeatherParams>({
    wea: [],
    wea_img: [],
    day: [],
    tem1: [],
    tem2: []
  })
  const [temData, setTemData] = useState<ITem>({
    days: [''],
    tem: [0],
    months: [''],
    rain: [0]
  })
  async function getForeData() {
    const foreData = await getWeather()
    const wea: string[] = []
    const wea_img: string[] = []
    const day: string[] = []
    const tem1: string[] = []
    const tem2: number[] = []
    foreData.data.forEach((value, _index) => {
      wea.push(value.wea)
      wea_img.push(value.wea_img)
      day.push(value.day.split('（')[0])
      // tem1.push(value.tem1.slice(0, -1))
      tem1.push(value.tem1)
      tem2.push(parseInt(value.tem2))
    })
    switch (foreData.data[0].win[0]) {
      case '东风': setwinddirection(0); break
      case '南风': setwinddirection(270); break
      case '西风': setwinddirection(180); break
      case '北风': setwinddirection(90); break
      case '东南风': setwinddirection(315); break
      case '东北风': setwinddirection(45); break
      case '西南风': setwinddirection(225); break
      case '西北风': setwinddirection(135); break
    }
    setWeaIcon(foreData.data[0].wea_img)
    // setTem(foreData.data[0].tem.split('℃')[0])
    // setWindLevel(formatWind(foreData.data[0].win_speed))
    setVisibility(formatVisibility(foreData.data[0].visibility))
    // setPressure(parseInt(foreData.data[0].pressure))
    setForecat({
      wea: wea,
      day: day,
      wea_img: wea_img,
      tem1: tem1,
      tem2: tem2
    })
  }

  async function getWeaData () {
    const data = await getWea()
    if (data.stat === 'OK') {
      const days: string[] = []
      const tem: number[] = []
      const months: string[] = []
      const rain: number[] = []
      data.AVGTemp.forEach((value) => {
        const date = new Date(value.time)
        days.push(`${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`)
        tem.push(value.Temperature)
      })
      data.every_month.forEach((value) => {
        months.push(`${value['MONTH(Time)']}月`)
        rain.push(value['AVG(RainFall)'])
      })
      setTemData({
        days: days,
        tem: tem,
        months: months,
        rain: rain
      })
    } else {
      message.error('获取历史天气数据失败！')
    }
  }
  useEffect(() => {
    (async function handleData() {
      await getForeData()
      await getWeaData()
    })()
  }, [])
  // 第一次挂载的时候执行一次
  useEffect(() => {
    (async function handleData () {
      await getAirTure()
    })()
  }, [])

  // 之后每隔十秒执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getAirTure()
      })()
    }, 180000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])
  return (
    <>
      <div className={styles.weather_wrap}>
        <BorderBox12
          className={styles.borderbox12}
        >
          <div className={styles.title_wrap}>
            <i className='icont-font icon-qixiangjiance module'></i>
            <h2 className={styles.title}>马岩墩气象态势</h2>
          </div>
          <div className={styles.top}>
            <div className={styles.factor}>
              <div className={styles.weather_box}>
                <i className={`icont-font icon-${weaIcon} weather`}></i>
                <span className={styles.temperature}>{temperature2}°</span>
              </div>
              <BarometricPressure pressure={pressure2} />
              <WindDirection angle={winddirection} />
            </div>
            <div className={styles.factor}>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-jiangyuliang'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>{rainfall}</span>
                  <span className={styles.label}>雨量mm</span>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-fengsu'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {windspeed}
                  {/* <i>m/s</i> */}
                  </span>
                  <span className={styles.label}>风速m/s</span>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-nengjiandu'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>{visibility.number}</span>
                  <span className={styles.label}>能见度{visibility.unit}</span>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-guangzhao'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {light}
                  </span>
                  <span className={styles.label}>光照Lux</span>
                </p>
              </div>
            </div>
            <div className={styles.charts}>
          {/* <div className={styles.air_quality}>
            <AirQualityLine />
            <AirQualityPie data={airPie} />
          </div> */}
          <div className={styles.factor}>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-a-PM25'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {pm2}
                    {/* <nav>换行</nav> */}
                    {/* <i>uk/m³</i> */}
                  </span>
                  <span className={styles.label}>PM2.5</span>
                  <nav className={styles.label}>uk/m³</nav>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-PM10'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {pm10}
                    {/* <i>uk/m³</i> */}
                  </span>
                  <span className={styles.label}>PM10</span>
                  <nav className={styles.label}>uk/m³</nav>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-shidu'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {humidity}
                    {/* <i>%</i> */}
                  </span>
                  <span className={styles.label}>湿度%</span>
                </p>
              </div>
              <div className={styles.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-fuyanglizi'></i>
                </span>
                <p className={styles.data}>
                  <span className={styles.number}>
                    {anion}
                    {/* <i>个/cm³</i> */}
                  </span>
                  <span className={styles.label}>负氧</span>
                  <nav className={styles.label}>个/cm³</nav>
                </p>
              </div>
            </div>
            {/* <PmTrend data={pmx} /> */}
        </div>
          </div>
          <WeatherForecat data={forecat} />
          <Temperature data={temData} />
          <PmTrend data={pmx} />
        </BorderBox12>
      </div>
    </>
  )
}
