// import axios from 'axios'
import { BorderBox12 } from '@jiaminghi/data-view-react'
import style from './style.module.scss'
import { useEffect, useRef, useState } from 'react'
import { getTea } from '@/services/bigdata'

export default function MosquitoPicData () {
// 图片url
  <meta http-equiv='Access-Control-Allow-Origin' content='*' />
  const intervalRef = useRef<any>()
  const [pic1, setpic1] = useState<string>('')
  const [pic2, setpic2] = useState<string>('')
  const [pic3, setpic3] = useState<string>('')
  const [pic4, setpic4] = useState<string>('')
  async function getTeaData () {
    const mockData = await getTea()
    setpic1(mockData.mc1_teaQuality[0].local_url)
    setpic2(mockData.mc1_weed[0].local_url)
    setpic3(mockData.nb1_teaQuality[0].local_url)
    setpic4(mockData.nb1_weed[0].local_url)
  }

  useEffect(() => {
    (async function handleData () {
      await getTeaData()
    })()
  }, [])

  // 之后每隔半小时执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getTeaData()
      })()
    }, 180000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])
  return (
    <div className={style.scoll_wrap}>
      <BorderBox12 className={style.borderbox12}>
        <div className={style.title_wrap}>
          <h2 className={style.title}>质量管理</h2>
        </div>
        <div className={style.bord_wrap}>
        <div className={style.row_wrap}>
          <div className={style.title_wrap}>
            <h2 className={style.title}>马岩墩</h2>
          </div>
          <img className={style.iocn_pic} src={pic1}></img>
          <img className={style.iocn_pic} src={pic2}></img>
        </div>
        <div className={style.row_wrap}>
          <div className={style.title_wrap}>
            <h2 className={style.title}>楠木桥</h2>
          </div>
          <img className={style.iocn_pic} src={pic3}></img>
          <img className={style.iocn_pic} src={pic4}></img>
        </div>
        </div>
      </BorderBox12>
    </div>
  )
}
