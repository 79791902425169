import Charts from '@/components/Common/Charts'

import { IonicOption, ISoilPrams } from './options'

import styles from './style.module.scss'

interface IProps {
  data: ISoilPrams
}

export default function Ionic (props: IProps) {
  return (
    <>
      <div className={styles.soil_chart}>
        <Charts
          key='soil'
          style={{
            width: '100%',
            height: '70%'
          }}
          option={IonicOption(props.data)}
        />
      </div>
    </>
  )
}
