import { BorderBox13 } from '@jiaminghi/data-view-react'
import { useState, useEffect, useRef } from 'react'
import Ionic from './Charts/ionic'
import Temperature from './Charts/temperature'
import { IDataPrams } from './Charts/options_temp'
import { IDataPrams2 } from './Charts/options_light'
import { getData, getSoil_lightData } from '@/services/bigdata'
import 'swiper/swiper.scss'
import style from './style.module.scss'
import AirQualityPie from './Charts/airQualityPie'

export default function BigData () {
  const intervalRef = useRef<any>()
  // 气温
  const [ionoc_temp, setIonoc_temp] = useState<IDataPrams>({
    avg_mb3: (1),
    avg_nb2: (1),
    date1: [''],
    date2: [''],
    mb3_ambientTemperature: [0],
    nb2_ambientTemperature: [0],
    ma_morethan_37: (1),
    ma_lessthan_3: (1),
    ma_between_3and10: (1),
    ma_between_10and33: (1),
    ma_between_33and37: (1),
    nan_between_3and10: (1),
    nan_between_10and33: (1),
    nan_between_33and37: (1),
    nan_morethan_37: (1),
    nan_lessthan_3: (1)
  })
  // 土壤湿度与光照
  const [ionoc_light, setIonoc_light] = useState<IDataPrams2>({
    date3: [''],
    date4: [''],
    mb3_ambientHumidity: [0],
    nb2_ambientHumidity: [0],
    mb3_lightIntensity: [0],
    nb2_lightIntensity: [0]
  })
  async function getBigData () {
    // 气温
    const mockData = await getData()
    const avg_ma = mockData.avg_mb3
    const avg_nan = mockData.avg_nb2
    if (mockData.status === 'success') {
      const avg_mb3 = (avg_ma)
      const avg_nb2 = (avg_nan)
      const date1: string[] = []
      const date2: string[] = []
      const nb2_ambientTemperature:number[] = []
      const mb3_ambientTemperature:number[] = []
      if (mockData.nb2_ambientTemperature !== undefined) {
        mockData.nb2_ambientTemperature.forEach((value) => {
          const time = new Date(value.time)
          date1.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          nb2_ambientTemperature.push(value.ambientTemperature)
        })
      }
      if (mockData.mb3_ambientTemperature !== undefined) {
        mockData.mb3_ambientTemperature.forEach((value) => {
          const time = new Date(value.time)
          date2.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          mb3_ambientTemperature.push(value.ambientTemperature)
        })
      }
      setIonoc_temp({
        avg_nb2: avg_nb2,
        avg_mb3: avg_mb3,
        date1: date1,
        date2: date2,
        mb3_ambientTemperature: mb3_ambientTemperature,
        nb2_ambientTemperature: nb2_ambientTemperature,
        ma_between_3and10: mockData.ma_between_3and10,
        ma_lessthan_3: mockData.ma_lessthan_3,
        ma_morethan_37: mockData.ma_morethan_37,
        ma_between_10and33: mockData.ma_between_10and33,
        ma_between_33and37: mockData.ma_between_33and37,
        nan_between_3and10: mockData.nan_between_3and10,
        nan_lessthan_3: mockData.nan_lessthan_3,
        nan_morethan_37: mockData.nan_morethan_37,
        nan_between_10and33: mockData.nan_between_10and33,
        nan_between_33and37: mockData.nan_between_33and37
      })
    }
    // 土壤和光照
    const lightData = await getSoil_lightData()
    if (lightData.status === 'success') {
      const date3: string[] = []
      const date4: string[] = []
      const mb3_ambientHumidity:number[] = []
      const nb2_ambientHumidity:number[] = []
      const mb3_lightIntensity:number[] = []
      const nb2_lightIntensity:number[] = []
      setmalight(lightData.mb3_lightIntensity_average)
      setnanlight(lightData.nb2_lightIntensity_average)
      setmaambientHumidity(lightData.mb3_ambientHumidity_average)
      setnanambientHumidity(lightData.nb2_ambientHumidity_average)
      if (lightData.mb3_ambientHumidity !== undefined) {
        lightData.mb3_ambientHumidity.forEach((value) => {
          const time = new Date(value.time)
          date3.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          mb3_ambientHumidity.push(value.ambientHumidity)
        })
      }
      if (lightData.nb2_ambientHumidity !== undefined) {
        lightData.nb2_ambientHumidity.forEach((value) => {
          const time = new Date(value.time)
          date4.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          nb2_ambientHumidity.push(value.ambientHumidity)
        })
      }
      if (lightData.mb3_lightIntensity !== undefined) {
        lightData.mb3_lightIntensity.forEach((value) => {
          mb3_lightIntensity.push(value.lightIntensity)
        })
      }
      if (lightData.nb2_lightIntensity !== undefined) {
        lightData.nb2_lightIntensity.forEach((value) => {
          nb2_lightIntensity.push(value.lightIntensity)
        })
      }
      setIonoc_light({
        date3: date3,
        date4: date4,
        mb3_ambientHumidity: mb3_ambientHumidity,
        nb2_ambientHumidity: nb2_ambientHumidity,
        mb3_lightIntensity: mb3_lightIntensity,
        nb2_lightIntensity: nb2_lightIntensity
      })
    }
  }
  const [avg_ma_light, setmalight] = useState<number>(0)
  const [avg_nan_light, setnanlight] = useState<number>(0)
  const [avg_ma_ambientHumidity, setmaambientHumidity] = useState<number>(0)
  const [avg_ambientHumidityt, setnanambientHumidity] = useState<number>(0)
  const a = Math.abs(((avg_ma_light / avg_nan_light) - 1) * 100)
  const light_percent = a.toFixed(0)
  const ambientHumidity_percent = Math.abs(((avg_ma_ambientHumidity / avg_ambientHumidityt) - 1) * 100).toFixed(0)
  // 第一次挂载的时候执行一次
  useEffect(() => {
    (async function handleData () {
      await getBigData()
    })()
  }, [])

  // 之后每隔一小时执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getBigData()
      })()
    }, 3600000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className={style.soil_wrap}>
      <BorderBox13 className={style.borderbox13}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-turang_ module'></i>
          <h2 className={style.title}>气候大数据</h2>
        </div>
        <div className={style.charts1}>
          <Ionic data1={ionoc_temp} />
        </div>
        <div className={style.charts2}>
          <AirQualityPie data={ionoc_temp}/>
        </div>
        <div className={style.charts1}>
          <Temperature data2={ionoc_light} />
        </div>
        <div className={style.charts3}>
            <i className={style.icons}>马岩墩月均光照: {avg_ma_light}</i>
            <i className={style.icons}>楠木桥月均光照：{avg_nan_light}</i>
            <i className={style.icons}>两地百分比差：{light_percent}%</i>
        </div>
        <div className={style.charts3}>
            <i className={style.icons}>马岩墩月均土壤湿度: {avg_ma_ambientHumidity}%</i>
            <i className={style.icons}>楠木桥月均土壤湿度：{avg_ambientHumidityt}%</i>
            <i className={style.icons}>两地百分比差：{ambientHumidity_percent}%</i>
        </div>
      </BorderBox13>
    </div>
  )
}
