import { ECOption } from '@/types'
import { blueColor, lineColor } from '@/utils/charts'

// 趋势参数
export interface IDataPrams2 {
  /**
   * 日期
   */
  date3: string[]
    /**
   * 日期2
   */
  date4: string[]
  /**
   * 马岩墩土壤湿度
   */
  mb3_ambientHumidity: number[]
  /**
   * 楠木桥土壤湿度
   */
  nb2_ambientHumidity: number[]
  /**
   * 马岩墩光照强度
   */
  mb3_lightIntensity: number[]
  /**
   * 楠木桥光照强度
   */
  nb2_lightIntensity: number[]
  /**
   * 马岩墩月均光照强度
   */
  // mb3_lightIntensity_average: number
  /**
   * 楠木桥月均光照强度
   */
  // nb2_lightIntensity_average: number
}

export const temOption = (params: IDataPrams2): ECOption => ({
  title: {
    text: '土壤湿度/光照大数据',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    show: true,
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '30%',
    left: '10%',
    right: '10%',
    bottom: '15%'
  },
  xAxis: [{
    // name: 'temperature',
    type: 'category',
    axisLine: {
      show: false
    },
    splitArea: {
      areaStyle: {
        color: ['#f00']
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 8,
      color: '#00b3f4'
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date3
  }, {
    // name: 'rain',
    type: 'category',
    offset: -5,
    position: 'top',
    axisLine: {
      show: false
    },
    splitArea: {
      areaStyle: {
        color: ['#f00']
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 8,
      color: '#00b3f4'
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.date4
  }],
  yAxis: [{
    type: 'value',
    name: '',
    position: 'left',
    nameTextStyle: {
      color: '#00b3f4'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      margin: 10,
      color: '#00b3f4'
    }
  }, {
    type: 'value',
    max: 100,
    min: 0,
    name: '',
    position: 'right',
    nameTextStyle: {
      color: '#91CC75'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      margin: 10,
      color: '#91CC75'
    }
  }],
  dataZoom: [
    {
      show: true,
      start: 80,
      end: 100,
      height: '6%',
      top: '90%'
    },
    {
      type: 'inside',
      start: 94,
      end: 100
    }
    // {
    //   show: true,
    //   yAxisIndex: 0,
    //   filterMode: 'empty',
    //   width: 25,
    //   height: '68%',
    //   showDataShadow: false,
    //   left: '94%'
    // }
  ],
  series: [{
    name: '马岩墩光照',
    type: 'line',
    xAxisIndex: 0,
    yAxisIndex: 0,
    data: params.mb3_lightIntensity,
    lineStyle: {
      color: '#ff00ff',
      shadowColor: 'rgba(0, 0, 0, .3)',
      shadowBlur: 0,
      shadowOffsetY: 5,
      shadowOffsetX: 5
    },
    symbolSize: 1,
    itemStyle: {
      color: '#ff00ff'
    },
    // 设置渐变色
    areaStyle: {
      color: lineColor,
      shadowColor: 'rgba(0, 179, 244, 0.9)',
      shadowBlur: 20
    }
  }, {
    name: '楠木桥光照',
    type: 'line',
    xAxisIndex: 1,
    yAxisIndex: 0,
    data: params.nb2_lightIntensity,
    lineStyle: {
      color: '#ffff00',
      shadowColor: 'rgba(0, 0, 0, .3)',
      shadowBlur: 0,
      shadowOffsetY: 5,
      shadowOffsetX: 5
    },
    symbolSize: 0,
    itemStyle: {
      color: '#ffff00'
    },
    // 设置渐变色
    areaStyle: {
      color: blueColor,
      shadowColor: 'rgba(0,202,149, 0.9)',
      shadowBlur: 20
    }
  }, {
    name: '马岩墩土壤湿度',
    type: 'bar',
    xAxisIndex: 0,
    yAxisIndex: 1,
    barWidth: 5,
    data: params.mb3_ambientHumidity,
    itemStyle: {
      color: 'rgba(0,100,210,0.8)'
    }
  }, {
    name: '楠木桥土壤湿度',
    type: 'bar',
    xAxisIndex: 0,
    yAxisIndex: 1,
    barWidth: 5,
    data: params.nb2_ambientHumidity,
    itemStyle: {
      color: 'rgba(0,210,100,0.8)'
    }
  }]
})
