import axios from 'axios'
import { ITea, IData, ISoil_lightData, IMosquitoData } from '@/model/bigdataType'

/**
 * @: 获取气温
 * @param {*}
 * @return {*}
 */
export async function getData () {
  const data = await axios.get<IData>('http://121.5.157.30:3000/api/select_info_qxy_temperature')
  return data.data
}

/**
 * @: 获取土壤湿度和光照
 * @param {*}
 * @return {*}
 */
export async function getSoil_lightData () {
  const data = await axios.get<ISoil_lightData>('http://121.5.157.30:3000/api/select_info_qxy_lightIntensity')
  return data.data
}
// 蚊虫识别图
export async function getMosquitoData () {
  const data = await axios.get<IMosquitoData>('http://121.5.157.30:3000/api/select_insectData')
  return data.data
}
// 茶叶质量、杂草图
export async function getTea () {
  const data = await axios.get<ITea>('http://121.5.157.30:3000/api/capturePictures')
  return data.data
}
