import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { BorderBox7 } from '@jiaminghi/data-view-react'
import { useState, useEffect } from 'react'

import { getMosquito } from '@/services/mosquito'
// import mosquitopicture1 from  '@assets/images/mosquito/mosquitopicture1.jpeg'
// import mosquitopicture2 from 'src\assets\images\mosquito\mosquito2.jpeg'

import style from './style.module.scss'

// 表格的数据结构
interface IColumn {
  /**
   * key
   */
  key: string
  /**
   * 开启状态
   */
  status: string
  /**
   * 是否自动
   */
  // auto: number
  /**
   * 开启时间
   */
  // time: string
  /**
   * 持续时长
   */
  // duration: string
  /**
   * 本月开启次数
   */
  // month: number
  /**
   * 全年开启次数
   */
  // year: number
  /**
   * 电池电量
   */
  cell: string
  /**
   * 补光灯状态（0关1开）
   */
  fillLight: string
  /**
   * 诱虫灯状态（0关1开）
   */
  attractWorm: string
  /**
   * 杀虫控制（0关1开）
   */
  insecticide: string
  /**
   * 运行模式（0关1开）
   */
  mode: string
}

export default function Mosquito () {
  const [dataSource, setDataSource] = useState<IColumn[]>([{
    key: 'mosquito',
    // status: 0,
    // auto: 0,
    // time: '',
    // duration: '',
    // month: 0,
    // year: 0,
    status: '',
    cell: '',
    fillLight: '',
    attractWorm: '',
    insecticide: '',
    mode: ''
  }])

  async function getData () {
    const da = await getMosquito()
    // if (da.status === 'online') {
    if (da[0].status === 'online') {
      // const time = new Date(da.data[0].)
      setDataSource([{
        key: 'mosquito',
        status: da[0].data.status,
        cell: da[0].data.cell,
        fillLight: da[0].data.fillLight,
        attractWorm: da[0].data.attractWorm,
        insecticide: da[0].data.insecticide,
        mode: da[0].data.mode
        // auto: parseInt(data.last[0].ArtificialOpen),
        // time: `${time.getHours()}:${time.getMinutes()}`,
        // duration: `${data.last[0].Duration}分钟`,
        // month: data.month,
        // year: data.all
      }])
    } else {
      // message.error('获取蚊虫诱杀数据失败！')
    }
    // console.log(da[0].status)
  }

  useEffect(() => {
    (async function handleData () {
      await getData()
    })()
  }, [])

  const column: ColumnsType<IColumn> = [{
    title: '设备',
    dataIndex: 'status',
    key: 'status',
    render: (value: number) => <Switch checkedChildren={'开启'} unCheckedChildren={'关闭'} defaultChecked={!!value} disabled />
  }, {
    title: '运行模式',
    dataIndex: 'auto',
    key: 'auto',
    render: (value: number) => <Switch checkedChildren={'自动'} unCheckedChildren={'人工'} defaultChecked={!value} disabled />
  }, {
    title: '电量',
    dataIndex: 'cell',
    key: 'cell'
  }, {
    title: '补光灯',
    dataIndex: 'fillLight',
    key: 'fillLight'
  }, {
    title: '诱虫灯',
    dataIndex: 'insecticide',
    key: 'insecticide'
  }, {
    title: '诱虫灯',
    dataIndex: 'insecticide',
    key: 'insecticide'
  }]
  return (
    <div className={style.mos_wrap}>
      <BorderBox7 className={style.borderbox7}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-tubiao-miewendeng module'></i>
          <h2 className={style.title}>蚊虫诱杀</h2>
        </div>
        <div className={style.tabel_wrap}>
          <Table
            size='small'
            columns={column}
            dataSource={dataSource}
            pagination={false}
          />
        </div>
        <div></div>
      </BorderBox7>
      {/* <div>
        <img src={mosquitopicture1} alt=""/>
      </div> */}
    </div>
  )
}
