import Charts from '@/components/Common/Charts'

import { windOption } from './options'

import styles from './style.module.scss'

interface IProps {
  angle: number
}

export default function windDirection (props: IProps) {
  return (
    <>
      <div className={styles.wind_chart}>
        <Charts
          key='windDirection'
          style={{
            width: '100%',
            height: '100%'
          }}
          option={windOption(props.angle)}
        />
      </div>
    </>
  )
}
