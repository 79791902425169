// import Particles from 'react-tsparticles'
import { Form, Button, Input, message, Layout } from 'antd'
import { useHistory } from 'react-router-dom'
import { useContext, useState, FC } from 'react'
import { UserOutlined, LockOutlined, WhatsAppOutlined } from '@ant-design/icons'

// import { option } from '@/pages/Login/options'
import { GlobalInfoContext } from '@/store'
import { IUser, IInfo } from '@/model/userType'
import { login, setLogin, register } from '@/services/user'
import { setStorage } from '@/utils'
// import Verification from './verification.js'
// import Verification from '../verification'
import styles from './style.module.scss'

// 手机号正则
const regPhone = /^[1]([3-9])[0-9]{9}$/

const Login: FC = () => {
  const { Footer } = Layout
  const history = useHistory()
  const [isSignUp, setIsSignUp] = useState<boolean>(false)
  const [classList, setClassList] = useState<string>(`${styles.container}`)
  const { setUserName } = useContext(GlobalInfoContext)
  const [signinForm] = Form.useForm<IUser>()
  const [signupForm] = Form.useForm<IInfo>()

  const signIn = () => {
    setIsSignUp(false)
    setClassList(`${styles.container}`)
  }

  // const signUp = () => {
  //   setIsSignUp(true)
  //   setClassList(`${styles.container} ${styles.right_panel_active}`)
  // }

  async function handleLogin () {
    const values: IUser = signinForm.getFieldsValue(['phone', 'password'])
    const data = await login(values.phone, values.password)
    if (data.data) {
      const recordData = await setLogin(values.phone)
      if (recordData.stat === 'OK') {
        setUserName(data.data[0].UserName)
        setStorage('username', data.data[0].UserName)
        setStorage('phone', values.phone)
        setStorage('password', values.password, true)
        history.push('/ma_1')
      } else {
        message.error(data.message)
      }
    } else {
      message.error(data.message)
    }
  }

  async function handleRegister () {
    const values: IInfo = signupForm.getFieldsValue(['phone', 'username', 'password'])
    const data = await register(values.username, values.phone, values.password)
    if (data.stat === 'OK') {
      signinForm.setFieldsValue({
        phone: values.phone,
        password: values.password
      })
      message.success('注册成功！')
      signIn()
    } else {
      message.error(data.message)
    }
  }

  return (
    <div className={styles.wrap}>
      {/* <Particles className={styles.particles} options={option} /> */}
      <div className={styles.login}>
        <div className={styles.title}>
          <span>智慧茶园开发技术分享</span>
        </div>
        <div className={classList}>
          <div className={`${styles.container_form} ${styles.container_signup}`}>
            <Form
              form={signupForm}
              className={styles.antd_form}
              onFinish={() => handleRegister()}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: '请输入用户名!' }]}
              >
                <Input id={styles.logup_username} className={styles.input} prefix={ <UserOutlined className={styles.icon} /> } placeholder="用户名" />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[{ required: true, message: '请输入正确的手机号!', pattern: regPhone }]}
              >
                <Input id={styles.logup_phone} className={styles.input} prefix={ <WhatsAppOutlined className={styles.icon} /> } placeholder="手机号" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
              >
                <Input.Password id={styles.logup_password} className={styles.input} prefix={ <LockOutlined className={styles.icon} /> } placeholder="密码" />
              </Form.Item>
              {/* <Form.Item style={{ marginTop: '8px' }}>
                <Button
                  className={styles.button}
                  block={true}
                  type="primary"
                  htmlType="submit"
                >
                  注册
                </Button>
              </Form.Item> */}
            </Form>
            {/* <Verification /> */}
          </div>
          <div className={`${styles.container_form} ${styles.container_signin} ${isSignUp ? styles.signin_opacity : ''}`}>
            <Form
              form={signinForm}
              className={styles.antd_form}
              onFinish={() => handleLogin()}
            >
              <Form.Item
                name="phone"
                rules={[{ required: true, message: '请输入正确的手机号!', pattern: regPhone }]}
              >
                <Input id={styles.login_phone} className={styles.input} prefix={ <WhatsAppOutlined className={styles.icon} /> } placeholder="手机号" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: '请输入密码!' }]}
              >
                <Input.Password id={styles.login_password} className={styles.input} prefix={ <LockOutlined className={styles.icon} /> } placeholder="密码" />
              </Form.Item>
              <Form.Item style={{ marginTop: '8px' }}>
                <Button
                  className={styles.button}
                  block={true}
                  type="primary"
                  htmlType="submit"
                >
                  登录
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.container_overlay}>
            <div className={styles.overlay}>
              <div className={`${styles.overlay_panel} ${styles.overlay_left}`}>
                <button className={styles.btn} onClick={signIn}>登录</button>
              </div>
              {/* <div className={`${styles.overlay_panel} ${styles.overlay_right}`}>
                <button className={styles.btn} onClick={signUp}>注册</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer style={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', color: 'white' }}>
      {/* <Footer style={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0)', color: 'white' }}> */}
        多米智慧 Design ©2023 <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2023030616号-1</a>
      </Footer>
    </div>

  )
}

export default Login
