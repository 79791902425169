import { ECOption } from '@/types'
import { lineColor } from '@utils/charts'
import Qing from '@assets/images/weather/qing.svg'
import Yun from '@assets/images/weather/yun.svg'
import Yu from '@assets/images/weather/yu.svg'
import Lei from '@assets/images/weather/lei.svg'
import Bingbao from '@assets/images/weather/bingbao.svg'
import Shachen from '@assets/images/weather/shachen.svg'
import Xue from '@assets/images/weather/xue.svg'
import Wu from '@assets/images/weather/wu.svg'
import Yin from '@assets/images/weather/yin.svg'

// 天气图表合集
const WeaImg = {
  qing: Qing,
  yun: Yun,
  yu: Yu,
  lei: Lei,
  bingbao: Bingbao,
  shachen: Shachen,
  xue: Xue,
  wu: Wu,
  yin: Yin
}

// 天气预报option传入的数据类型
export interface IWeatherParams {
  /**
   * 天气
   */
  day: string[],
  /**
   * 图标名称
   */
  wea_img: string[],
  /**
   * 天气情况
   */
  wea: string[],
  /**
   * 最高气温
   */
  tem1: string[],
  /**
   * 最低气温,用数字是为了获取y轴的起始值
   */
  tem2: number[]
}

export const weaOption = (params: IWeatherParams, minTem: number): ECOption => ({
  grid: {
    show: true,
    backgroundColor: 'transparent',
    // opacity: 0.3,
    borderWidth: 0,
    top: 100,
    bottom: 0
  },
  title: {
    show: true,
    text: '一周天气预报',
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    show: false
  },
  xAxis: [
    // 日期
    {
      type: 'category',
      boundaryGap: false,
      position: 'top',
      offset: 60,
      zlevel: 100,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        formatter: [
          '{a|{value}}'
        ].join('\n'),
        rich: {
          a: {
            color: 'white',
            fontSize: 12
          }
        }
      },
      nameTextStyle: {

      },
      data: params.day
    },
    // 天气图标
    {
      type: 'category',
      boundaryGap: false,
      position: 'top',
      offset: 20,
      zlevel: 100,
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        interval: 0,
        formatter: function(value: any, index: number) {
          return '{' + index + '| }\n{b|' + value + '}'
        },
        rich: {
          0: {
            backgroundColor: {
              image: WeaImg[params.wea_img[0] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          1: {
            backgroundColor: {
              image: WeaImg[params.wea_img[1] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          2: {
            backgroundColor: {
              image: WeaImg[params.wea_img[2] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          3: {
            backgroundColor: {
              image: WeaImg[params.wea_img[3] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          4: {
            backgroundColor: {
              image: WeaImg[params.wea_img[4] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          5: {
            backgroundColor: {
              image: WeaImg[params.wea_img[5] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          6: {
            backgroundColor: {
              image: WeaImg[params.wea_img[6] as keyof typeof WeaImg]
            },
            height: 20,
            width: 20
          },
          b: {
            // color: 'white',
            fontSize: 12,
            lineHeight: 20,
            height: 20
          }
        }
      },
      nameTextStyle: {
        fontWeight: 'bold',
        fontSize: 10
      },
      // data: this.weatherdata.weather
      data: params.wea
    }
  ],
  yAxis: {
    type: 'value',
    show: false,
    min: minTem - 5, // 需根据获取的所有预报数值的最小值的整数，不然图例不会在底部
    axisLabel: {
      color: '#20dbfd'
    }
  },
  series: [
    {
      name: '最高气温',
      type: 'line',
      data: params.tem1,
      symbol: 'emptyCircle',
      symbolSize: 8,
      showSymbol: true,
      smooth: true,
      itemStyle: {
        color: '#C95843'
      },
      label: {
        show: true,
        fontSize: 10,
        position: 'top',
        color: 'white',
        formatter: '{c} °C'
      },
      lineStyle: {
        width: 1,
        color: 'white'
      },
      areaStyle: {
        opacity: 1,
        color: 'transparent'
      }
    },
    {
      name: '最低气温',
      type: 'line',
      data: params.tem2,
      symbol: 'emptyCircle',
      symbolSize: 8,
      showSymbol: true,
      smooth: true,
      itemStyle: {
        color: 'blue'
      },
      label: {
        show: true,
        fontSize: 10,
        position: 'bottom',
        color: 'white',
        formatter: '{c} °C'
      },
      lineStyle: {
        width: 1,
        color: 'white'
      },
      areaStyle: {
        opacity: 1,
        color: 'transparent'
      }
    }
  ]
})

export const BarOption = (value: number): ECOption => ({
  series: {
    type: 'gauge',
    min: 700,
    max: 1500,
    splitNumber: 8,
    center: ['50%', '50%'],
    axisLine: {
      lineStyle: {
        color: [[1, '#FAC858']],
        width: 2
      }
    },
    splitLine: {
      distance: 0,
      length: 12,
      lineStyle: {
        color: '#FAC858',
        width: 2
      }
    },
    axisTick: {
      distance: 0,
      length: 6,
      lineStyle: {
        color: '#FAC858'
      }
    },
    axisLabel: {
      distance: -25,
      fontSize: 8,
      color: '#FFFFFF'
    },
    title: {
      show: true,
      color: '#ffffff',
      fontSize: 10,
      offsetCenter: ['0%', '90%']
    },
    anchor: {
      show: true,
      size: 12,
      itemStyle: {
        color: '#FAC858'
      }
    },
    pointer: {
      offsetCenter: [0, '10%'],
      icon:
          'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
      length: '70%',
      width: 4,
      itemStyle: {
        color: '#FAC858'
      }
    },
    detail: {
      valueAnimation: true,
      precision: 1,
      fontWeight: 'bold',
      fontSize: 15,
      color: '#89e5ff',
      offsetCenter: ['0%', '60%']
    },
    data: [
      {
        value: value,
        name: '百帕'
      }
    ]
  }
})

export const windOption = (value: number): ECOption => ({
  tooltip: {
    show: false,
    formatter: '{a} <br/>{b} : {c}级'
  },
  series: [
    // 这里是我们需要用到的仪表盘
    {
      type: 'gauge',
      startAngle: 0,
      endAngle: 360,
      min: 0,
      max: 360,
      z: 8,
      center: ['50%', '50%'],
      radius: '50%',
      axisTick: {
        show: false
      },
      // 仪表盘指针。
      pointer: {
        show: true,
        // 指针样式
        icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAiCAMAAABcDciTAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABFFBMVEX/AAD/AAH/AQL/AQH/AAL/////AAD/AAH/AQL/AAD/AAD/AAD/AAD/AAH/AAD/AQL/AQL/AAD/AAL/AAD/AAD/AAH/AAD/AAD/AAD/AAD/AQH/AAD/AAD/AAD/AQL/AAD/AAD/AAD/AAD/AAL/AAD/AAD/AAD/AAD/AAD/AAH/AAL/AAD/AAL/Cgr/Dg7/Cgr/Dg//DQ//DQ7/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAD/AAH/AQL/AQH/AAL///8EndKmAAAAVnRSTlMAAAAAAAAXJ2R4s8gM9v4aUGmguQPr+g8+W4yq3PMHK0x6m8jpAho9Zowatdwx9PX09PQxGrXcZoz+PcjpAnqbK0zzB6o+WwPr+g+guVBpDPazZHgXJ2NJFc8AAAABYktHRAX4b+nHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH5QcVBiY1zlpgmQAAAOZJREFUGNNNzOdawkAQheHROIoNe+8NKy5KRBB771gPCXv/92FmdhM8P5Lv3WcTIll3T4eMOmW5Xmqvr1/PtAcG80MKeQwDI9mtUWAs7fEJYHLKY7qJJmakgiCYTRpz/m/zQISFRcWSdBwt6ycrEGFVsQa0YmB9I+lCHm6bRF1bcgdxjG2inV09biHaK1Bx35jSQcmYQ1Okss1WpvAo7UpIfJyiysRhzeMkAdddn54Jzh0uWHDpcKXgivT1jUNVcMsOd4J7j4dHa5+ePfjF2ldO8Wbte4bGRy3MwJ91buPr+x9+fvX1BzPHP7+pF5ngAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA3LTIxVDA2OjM4OjI2KzAwOjAwIPtDQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNy0yMVQwNjozODo1MyswMDowMAlb3UIAAAAASUVORK5CYII=',
        length: '80%', // 指针长度
        width: 7, // 指针宽度
        offsetCenter: [0, '50%'], // 指针位置
        keepAspect: false,
        itemStyle: {
          color: '#ffc600'
        }
      },
      // 坐标轴线，也就是圆圈的边框线
      axisLine: {
        show: false
      },
      // 分割线
      splitLine: {
        show: true,
        // length: '100%', // 待提issue
        length: 35,
        lineStyle: {
          color: '#fff',
          width: 1
        }
      },
      splitNumber: 4,
      // 刻度标签
      axisLabel: {
        show: true,
        color: '#fff',
        distance: 15,
        fontSize: 12,
        formatter: function (value: number) {
          switch (value) {
            case 0:
              return 'W'
            case 90:
              return 'S'
            case 180:
              return 'E'
            case 270:
              return 'N'
            default:
              return ''
          }
        }
      },
      title: {
        show: true,
        color: '#ffffff',
        fontSize: 10,
        offsetCenter: ['100%', '130%']
      },
      // 最下边数值的设置
      detail: {
        fontWeight: 'bold',
        fontSize: 13,
        color: '#89e5ff',
        offsetCenter: ['100%', '80%'],
        formatter: function (value: number) {
          if (value === 0) return '东风'
          if (value === 90) return '北风'
          if (value === 180) return '西风'
          if (value === 270) return '南风'
          if (value > 0 && value < 90) return '东北风'
          if (value > 90 && value < 180) return '西北风'
          if (value > 180 && value < 270) return '西南风'
          if (value > 270 && value < 360) return '东南风'
          return '数据错误'
        }
      },
      data: [
        {
          value: value,
          name: '风向'
        }
      ]
    },
    // 这里是内部填充用的饼图
    {
      type: 'pie',
      startAngle: 90,
      radius: '70%',
      emphasis: {
        scale: false
      },
      legendHoverLink: false,
      labelLine: {
        show: false
      },
      center: ['50%', '50%'],
      z: -10,
      data: [
        {
          value: 100,
          itemStyle: {
            color: 'rgba(84,136,255, 0.5)'
          }
        }
      ]
    }
  ]
})

// 趋势参数
export interface ITem {
  /**
   * 日期
   */
  days: string[]
  /**
   * 气温
   */
  tem: number[]
  /**
   * 月份
   */
  months: string[]
  /**
   * 降雨量
   */
  rain: number[]
}

export const temOption = (params: ITem): ECOption => ({
  title: {
    text: '气温/降雨走势',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    show: true,
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '30%',
    left: '10%',
    right: '10%',
    bottom: '15%'
  },
  xAxis: [{
    // name: 'temperature',
    type: 'category',
    position: 'bottom',
    axisLine: {
      show: false
    },
    splitArea: {
      areaStyle: {
        color: ['#f00']
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 8,
      color: '#00b3f4'
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.days
  }, {
    // name: 'rain',
    type: 'category',
    offset: -5,
    position: 'top',
    axisLine: {
      show: false
    },
    splitArea: {
      show: false
    },
    axisLabel: {
      color: '#91CC75'
    },
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },
    boundaryGap: false,
    data: params.months
  }],
  yAxis: [{
    type: 'value',
    name: '',
    position: 'left',
    nameTextStyle: {
      color: '#00b3f4'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      margin: 10,
      color: '#00b3f4'
    }
  }, {
    type: 'value',
    name: '',
    position: 'right',
    nameTextStyle: {
      color: '#91CC75'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      margin: 10,
      color: '#91CC75'
    }
  }],
  series: [{
    name: '气温',
    type: 'line',
    xAxisIndex: 0,
    yAxisIndex: 0,
    data: params.tem,
    lineStyle: {
      color: '#00b3f4',
      shadowColor: 'rgba(0, 0, 0, .3)',
      shadowBlur: 0,
      shadowOffsetY: 5,
      shadowOffsetX: 5
    },
    symbolSize: 0,
    itemStyle: {
      color: '#00b3f4'
    },
    // 设置渐变色
    areaStyle: {
      color: lineColor,
      shadowColor: 'rgba(0, 179, 244, 0.9)',
      shadowBlur: 20
    },
    markLine: {
      silent: false,
      symbol: 'none',
      lineStyle: {
        type: 'dashed',
        color: 'red'
      },
      data: [{
        yAxis: 15,
        name: '高温红线',
        label: {
          position: 'insideEndBottom',
          formatter: '高温红线',
          fontSize: 10
        }
      }, {
        yAxis: 2,
        name: '低温红线',
        label: {
          position: 'insideEndTop',
          formatter: '低温红线',
          fontSize: 10
        }
      }]
    }
  }, {
    name: '降雨量',
    type: 'bar',
    xAxisIndex: 1,
    yAxisIndex: 1,
    data: params.rain,
    itemStyle: {
      color: '#91CC75'
    }
  }]
})
