import { FC } from 'react'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import { Route } from 'react-router-dom'
import Header from '@/components/Home/Header'
import Header_mo from '@/components/Home/Header_mo'
import Weather from '@/components/Home/Weather'
import Weather_nan from '@/components/Home/Weather_nan'
import Prewarning from '@/components/Home/Prewarning'
// import Maptrans from '@/components/Home/Maptrans'
import trans_ma1 from '@/components/Home/Maptrans/tran_ma1'
import trans_ma2 from '@/components/Home/Maptrans/tran_ma2'
import trans_ma3 from '@/components/Home/Maptrans/tran_ma3'
import trans_ma4 from '@/components/Home/Maptrans/tran_ma4'
import trans_nan1 from '@/components/Home/Maptrans/tran_nan1'
import trans_nan2 from '@/components/Home/Maptrans/tran_nan2'
// import TimeBox from '@/components/Home/TimeBox'
import Map_ma1 from '@/components/Home/Map_ma1'
import Map_ma2 from '@/components/Home/Map_ma2'
import Map_ma3 from '@/components/Home/Map_ma3'
import Map_ma4 from '@/components/Home/Map_ma4'
import Map_nan1 from '@/components/Home/Map_nan1'
import Map_nan2 from '@/components/Home/Map_nan2'
// import Map_ma2 from '@/components/Home/Map_ma2'
import Mosquito from '@/components/Home/Mosquito'
import MosquitoPic from '@/components/Home/MosquitoPic'
// import Fertilizer from '@/components/Home/Fertilizer'
import Monitor1 from '@/components/Home/All of Monitors/ma1'
import Monitor2 from '@/components/Home/All of Monitors/mb1'
import Monitor3 from '@/components/Home/All of Monitors/mb4'
import Monitor4 from '@/components/Home/All of Monitors/mc1'
import Monitor5 from '@/components/Home/All of Monitors/md1'
import Monitor6 from '@/components/Home/All of Monitors/md2'
import Monitor7 from '@/components/Home/All of Monitors/na1'
import Monitor8 from '@/components/Home/All of Monitors/nb1'
// import Monitors from '@/components/Home/Monitors'
// import Meteorological from '@/components/Home/Meteorological'
import Soil from '@/components/Home/Soil'
import Soil_ma2 from '@/components/Home/Soil_ma2'
import Soil_ma3 from '@/components/Home/Soil_ma3'
import Soil_ma4 from '@/components/Home/Soil_ma4'
import Soil_nan2 from '@/components/Home/Soil_nan2'
import styles from './style.module.scss'
// import { BrowserRouter, Route } from 'react-router-dom'
const Home: FC = () => {
  return (
    // <BrowserRouter >
    <FullScreenContainer>
      <div className={styles.container}>
      <Route path='/ma_1' component={Header}/>
      <Route path='/ma_2_mb1' component={Header}/>
      <Route path='/ma_2_mb4' component={Header}/>
      <Route path='/ma_3' component={Header}/>
      <Route path='/ma_4_md1' component={Header}/>
      <Route path='/ma_4_md2' component={Header}/>
      <Route path='/nan_1' component={Header}/>
      <Route path='/nan_2' component={Header}/>
      <Route path='/monitors' component={Header_mo}/>
        <div className={styles.content_wrap}>
          <div className={styles.left_content}>
          {/* <Route path='/monitors' component={Monitor1}/> */}
          {/* <Route path='/monitors' component={Monitor4}/> */}
          {/* <Route path='/monitors' component={Monitor7}/> */}
          <Route path='/ma_1' component={Weather}/>
          <Route path='/ma_2_mb1' component={Weather}/>
          <Route path='/ma_2_mb4' component={Weather}/>
          <Route path='/ma_3' component={Weather}/>
          <Route path='/ma_4_md1' component={Weather}/>
          <Route path='/ma_4_md2' component={Weather}/>
          <Route path='/nan_1' component={Weather_nan}/>
          <Route path='/nan_2' component={Weather_nan}/>
            {/* <Meteorological /> */}
            {/* <Prewarning /> */}
          </div>
          <div className={styles.center_content}>
          {/* <TimeBox /> */}
            {/* <Map /> */}
            {/* <Route path='/monitors' component={Monitor2}/> */}
            {/* <Route path='/monitors' component={Monitor5}/> */}
            {/* <Route path='/monitors' component={Monitor8}/> */}
            <Route path="/ma_1" component={Monitor1}/>
            <Route path="/ma_2_mb1" component={Monitor2}/>
            <Route path="/ma_2_mb4" component={Monitor3}/>
            <Route path="/ma_3" component={Monitor4}/>
            <Route path="/ma_4_md1" component={Monitor5}/>
            <Route path="/ma_4_md2" component={Monitor6}/>
            <Route path="/nan_1" component={Monitor7}/>
            <Route path="/nan_2" component={Monitor8}/>
            <Route path="/ma_1" component={trans_ma1}/>
            <Route path="/ma_2_mb1" component={trans_ma2}/>
            <Route path="/ma_2_mb4" component={trans_ma2}/>
            <Route path="/ma_3" component={trans_ma3}/>
            <Route path="/ma_4_md1" component={trans_ma4}/>
            <Route path="/ma_4_md2" component={trans_ma4}/>
            <Route path="/nan_1" component={trans_nan1}/>
            <Route path="/nan_2" component={trans_nan2}/>
            {/* <Maptrans/> */}
            {/* <div className={styles.row_wrap}>
              <NavLink className={styles.icons} to="/ma_1">马岩墩一</NavLink>
              <NavLink className={styles.icons} to="/ma_2_mb1">马岩墩二</NavLink>
              <NavLink className={styles.icons} to="/ma_3">马岩墩三</NavLink>
              <NavLink className={styles.icons} to="/ma_4_md1">马岩墩四</NavLink>
              <NavLink className={styles.icons} to="/nan_1">楠木桥一</NavLink>
              <NavLink className={styles.icons} to="/nan_2">楠木桥二</NavLink>
          </div> */}
            <Route path="/ma_1" component={Mosquito}/>
            <Route path="/ma_2_mb1" component={Mosquito}/>
            <Route path="/ma_2_mb4" component={Mosquito}/>
            <Route path="/ma_3" component={Mosquito}/>
            <Route path="/ma_4_md1" component={Mosquito}/>
            <Route path="/ma_4_md2" component={Mosquito}/>
            <Route path="/nan_1" component={Mosquito}/>
            <Route path="/nan_2" component={Mosquito}/>
            <MosquitoPic/>
            {/* <Mosquito /> */}
            {/* <Fertilizer /> */}
          </div>
          <div className={styles.right_content}>
            {/* <TimeBox /> */}
            {/* <Monitor /> */}
            {/* <Route path='/monitors' component={Monitor3}/> */}
            {/* <Route path='/monitors' component={Monitor6}/> */}
            <Route path='/ma_1' component={Map_ma1}/>
            <Route path='/ma_2_mb1' component={Map_ma2}/>
            <Route path='/ma_2_mb4' component={Map_ma2}/>
            <Route path='/ma_3' component={Map_ma3}/>
            <Route path='/ma_4_md1' component={Map_ma4}/>
            <Route path='/ma_4_md2' component={Map_ma4}/>
            <Route path='/nan_1' component={Map_nan1}/>
            <Route path='/nan_2' component={Map_nan2}/>
            {/* <Route path="/ma_4" component={Monitor4}/>
            <Route path="/ma_1" component={Monitor1}/>
            <Route path="/ma_2" component={Monitor2}/> */}
            <Route path="/ma_1" component={Prewarning}/>
            <Route path="/ma_2_mb1" component={Prewarning}/>
            <Route path="/ma_2_mb4" component={Prewarning}/>
            <Route path="/ma_3" component={Prewarning}/>
            <Route path="/ma_4_md1" component={Prewarning}/>
            <Route path="/ma_4_md2" component={Prewarning}/>
            <Route path="/nan_1" component={Prewarning}/>
            <Route path="/nan_2" component={Prewarning}/>
            {/* <Prewarning /> */}
            {/* <Meteorological /> */}
            <Route path='/ma_1' component={Soil}/>
            <Route path='/ma_2_mb1' component={Soil_ma2}/>
            <Route path='/ma_2_mb4' component={Soil_ma2}/>
            <Route path='/ma_3' component={Soil_ma3}/>
            <Route path='/ma_4_md1' component={Soil_ma4}/>
            <Route path='/ma_4_md2' component={Soil_ma4}/>
            <Route path='/nan_1' component={Soil_nan2}/>
            <Route path='/nan_2' component={Soil_nan2}/>
          </div>
        </div>
      </div>
    </FullScreenContainer>
    // </BrowserRouter>
  )
}

export default Home
