import { useState } from 'react'

import { ICameraStore } from '@/model/cameraType'
import { globalInfo, GlobalInfoContext } from './index'

function GlobalInfoContextProvider (props: any) {
  const setUserName = (userName: string) => {
    setState({ ...state, userName: userName })
  }
  const setPhone = (phone: string) => {
    setState({ ...state, phone: phone })
  }
  const setCamera = (camera: ICameraStore) => {
    setState({ ...state, camera: { ...camera } })
  }
  const initState = {
    ...globalInfo,
    setUserName: setUserName,
    setPhone: setPhone,
    setCamera: setCamera
  }
  const [state, setState] = useState(initState)

  return (
    <GlobalInfoContext.Provider value={state}>
      {props.children}
    </GlobalInfoContext.Provider>
  )
}

export default GlobalInfoContextProvider
