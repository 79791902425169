// import axios from 'axios'
import { BorderBox13 } from '@jiaminghi/data-view-react'
import style from './style.module.scss'
import { useEffect, useRef, useState } from 'react'
import { getMosquitopic } from '@/services/mosquito'

export default function Prewarning () {
// 图片url
  <meta http-equiv='Access-Control-Allow-Origin' content='*' />
  // <meta name="referrer" content="no-referrer" />
  // const url = 'http://images.jnrsmcu.com/wormImages/c0f1fa3f9f954476b90a553c644aa694.jpg'
  const intervalRef = useRef<any>()
  const [pic, setpic] = useState<string[]>([''])
  async function getpic () {
    // const [image, setIamge] = useState('')
    const mockData = await getMosquitopic()
    if (mockData.stat === 'OK') {
      setpic(mockData.imagesUrlList)
    }
  }

  useEffect(() => {
    (async function handleData () {
      await getpic()
    })()
  }, [])

  // 之后每隔半小时执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getpic()
      })()
    }, 180000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])
  // useEffect(() => {
  //   axios.get(
  //     url,
  //     { responseType: 'blob' }
  //   ).then((response) => {
  //     const codeUrl = window.URL.createObjectURL(response.data)
  //     setIamge(codeUrl)
  //   })
  // }, [])
  return (
    <div className={style.scoll_wrap}>
      <BorderBox13 className={style.borderbox13}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-yujing module'></i>
          <h2 className={style.title}>蚊虫近况图片</h2>
        </div>
        <div className={style.bord_wrap}>
        <div className={style.row_wrap}>
          <img src={pic[0]}></img>
          <img src={pic[1]}></img>
          <img src={pic[2]}></img>
          {/* <img src={url}></img> */}
          {/* <img src='http://images.jnrsmcu.com/wormImages/pictureProcessing/pictureProcessingd336acc1033f46f29f697d541a6daf863536327874098195178.jpg'></img> */}
          {/* <iframe><img src= 'http://images.jnrsmcu.com/wormImages/c0f1fa3f9f954476b90a553c644aa694.jpg' style= {{ width: 'auto', height: 'auto' }} ></img></iframe> */}
          {/* <iframe><img src={image} style= {{ width: '50' }} ></img></iframe> */}
          {/* <Image source={require(http://cq.sub.farm.0531yun.cn:7077/static/wormImages/pictureProcessing/pictureProcessing1125210319030.jpg)}/> */}
          {/* <iframe src='about:blank' onLoad = {this.contentwindow.document.body.innerHTML='img src=http://cq.sub.farm.0531yun.cn:7077/static/wormImages/pictureProcessing/pictureProcessing1125210319030.jpg'}></> */}
        </div>
        </div>
      </BorderBox13>
    </div>
  )
}
