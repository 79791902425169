import Charts from '@/components/Common/Charts'

import { weaOption, IWeatherParams } from './options'

import styles from './style.module.scss'

interface IProps {
  data: IWeatherParams
}

export default function WeatherForecat (props: IProps) {
  return (
    <>
      <div className={styles.fore_chart}>
        <Charts
          key='weatherForecat'
          style={{
            width: '100%',
            height: '100%'
          }}
          option={weaOption(props.data, Math.min.apply(null, props.data.tem2))}
        />
      </div>
    </>
  )
}
