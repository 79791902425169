import axios from 'axios'

import { IAir, IAirTure, IMockAir } from '@/model/airType'

// 路由前缀
const PREFIX = '/auto/api/select_air'

/**
 * @: 获取气象模块数据
 * @param {*}
 * @return {*}
 */
export async function getAir () {
  const data = await axios.get<IAir>(PREFIX)
  return data.data
}

/**
 * @: 获取气象模拟数据
 * @param {*}
 * @return {*}
 */
export async function getMockAir () {
  const data = await axios.get<IMockAir>(`/local${PREFIX}`)
  return data.data
}

/**
 * @: 获取气象仪模块真实数据
 * @param {*}
 * @return {*}
 */
export async function getAirTure () {
  const data = await axios.post<IAirTure>('http://121.5.157.30:3000/api/select_meteorological_mb3')
  return data.data
}

export async function getAirTure2() {
  const data = await axios.post<IAirTure>('http://121.5.157.30:3000/api/select_meteorological_nb2')
  return data.data
}
