import Charts from '@/components/Common/Charts'

import { BarOption } from './options'

import styles from './style.module.scss'

interface IProps {
  pressure: number
}

export default function BarometricPressure (props: IProps) {
  return (
    <>
      <div className={styles.baro_chart}>
        <Charts
          key='barometricPressure'
          style={{
            width: '100%',
            height: '100%'
          }}
          option={BarOption(props.pressure)}
        />
      </div>
    </>
  )
}
