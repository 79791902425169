import Mock from 'mockjs'

// 请求形式与返回数据的定义
export default Mock.mock('/local/auto/api/select_info_soil', 'get', {
  stat: 'OK',
  message: '查询成功！',
  dan: [
    {
      time: '2022-03-01T07:40:32.000Z',
      N: 37
    },
    {
      time: '2022-03-02T07:40:32.000Z',
      N: 37
    },
    {
      time: '2022-03-03T07:40:32.000Z',
      N: 26
    },
    {
      time: '2022-03-04T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-05T07:40:32.000Z',
      N: 26
    },
    {
      time: '2022-03-06T07:40:32.000Z',
      N: 16
    },
    {
      time: '2022-03-07T07:40:32.000Z',
      N: 16
    },
    {
      time: '2022-03-08T07:40:32.000Z',
      N: 16
    },
    {
      time: '2022-03-09T07:40:32.000Z',
      N: 12
    },
    {
      time: '2022-03-10T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-11T07:40:32.000Z',
      N: 26
    },
    {
      time: '2022-03-12T07:40:32.000Z',
      N: 15
    },
    {
      time: '2022-03-13T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-14T07:40:32.000Z',
      N: 16
    },
    {
      time: '2022-03-15T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-16T07:40:32.000Z',
      N: 16
    },
    {
      time: '2022-03-17T07:40:32.000Z',
      N: 26
    },
    {
      time: '2022-03-18T07:40:32.000Z',
      N: 6
    },
    {
      time: '2022-03-19T07:40:32.000Z',
      N: 37
    },
    {
      time: '2022-03-20T07:40:32.000Z',
      N: 17
    },
    {
      time: '2022-03-21T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-22T07:40:32.000Z',
      N: 24
    },
    {
      time: '2022-03-23T07:40:32.000Z',
      N: 27
    },
    {
      time: '2022-03-24T07:40:32.000Z',
      N: 42
    },
    {
      time: '2022-03-25T07:40:32.000Z',
      N: 42
    },
    {
      time: '2022-03-26T07:40:32.000Z',
      N: 12
    },
    {
      time: '2022-03-27T07:40:32.000Z',
      N: 14
    },
    {
      time: '2022-03-28T07:40:32.000Z',
      N: 48
    },
    {
      time: '2022-03-29T07:40:32.000Z',
      N: 21
    },
    {
      time: '2022-03-30T07:40:32.000Z',
      N: 28
    }
  ],
  lin: [
    {
      time: '2022-03-01T07:40:32.000Z',
      P: 26
    },
    {
      time: '2022-03-02T07:40:32.000Z',
      P: 15
    },
    {
      time: '2022-03-03T07:40:32.000Z',
      P: 17
    },
    {
      time: '2022-03-04T07:40:32.000Z',
      P: 19
    },
    {
      time: '2022-03-05T07:40:32.000Z',
      P: 13
    },
    {
      time: '2022-03-06T07:40:32.000Z',
      P: 16
    },
    {
      time: '2022-03-07T07:40:32.000Z',
      P: 17
    },
    {
      time: '2022-03-08T07:40:32.000Z',
      P: 10
    },
    {
      time: '2022-03-09T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-10T07:40:32.000Z',
      P: 12
    },
    {
      time: '2022-03-11T07:40:32.000Z',
      P: 26
    },
    {
      time: '2022-03-12T07:40:32.000Z',
      P: 17
    },
    {
      time: '2022-03-13T07:40:32.000Z',
      P: 27
    },
    {
      time: '2022-03-14T07:40:32.000Z',
      P: 18
    },
    {
      time: '2022-03-15T07:40:32.000Z',
      P: 27
    },
    {
      time: '2022-03-16T07:40:32.000Z',
      P: 8
    },
    {
      time: '2022-03-17T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-18T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-19T07:40:32.000Z',
      P: 27
    },
    {
      time: '2022-03-20T07:40:32.000Z',
      P: 25
    },
    {
      time: '2022-03-21T07:40:32.000Z',
      P: 27
    },
    {
      time: '2022-03-22T07:40:32.000Z',
      P: 24
    },
    {
      time: '2022-03-23T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-24T07:40:32.000Z',
      P: 26
    },
    {
      time: '2022-03-25T07:40:32.000Z',
      P: 20
    },
    {
      time: '2022-03-26T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-27T07:40:32.000Z',
      P: 27
    },
    {
      time: '2022-03-28T07:40:32.000Z',
      P: 28
    },
    {
      time: '2022-03-29T07:40:32.000Z',
      P: 23
    },
    {
      time: '2022-03-30T07:40:32.000Z',
      P: 17
    }
  ],
  jia: [
    {
      time: '2022-03-01T07:40:32.000Z',
      K: 10
    },
    {
      time: '2022-03-02T07:40:32.000Z',
      K: 26
    },
    {
      time: '2022-03-03T07:40:32.000Z',
      K: 17
    },
    {
      time: '2022-03-04T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-05T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-06T07:40:32.000Z',
      K: 25
    },
    {
      time: '2022-03-07T07:40:32.000Z',
      K: 17
    },
    {
      time: '2022-03-08T07:40:32.000Z',
      K: 28
    },
    {
      time: '2022-03-09T07:40:32.000Z',
      K: 12
    },
    {
      time: '2022-03-10T07:40:32.000Z',
      K: 25
    },
    {
      time: '2022-03-11T07:40:32.000Z',
      K: 26
    },
    {
      time: '2022-03-12T07:40:32.000Z',
      K: 36
    },
    {
      time: '2022-03-13T07:40:32.000Z',
      K: 37
    },
    {
      time: '2022-03-14T07:40:32.000Z',
      K: 18
    },
    {
      time: '2022-03-15T07:40:32.000Z',
      K: 26
    },
    {
      time: '2022-03-16T07:40:32.000Z',
      K: 17
    },
    {
      time: '2022-03-17T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-18T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-19T07:40:32.000Z',
      K: 26
    },
    {
      time36: '2022-03-20T07:40:32.000Z',
      K: 25
    },
    {
      time: '2022-03-21T07:40:32.000Z',
      K: 36
    },
    {
      time: '2022-03-22T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-23T07:40:32.000Z',
      K: 46
    },
    {
      time: '2022-03-24T07:40:32.000Z',
      K: 27
    },
    {
      time: '2022-03-25T07:40:32.000Z',
      K: 17
    },
    {
      time: '2022-03-26T07:40:32.000Z',
      K: 25
    },
    {
      time: '2022-03-27T07:40:32.000Z',
      K: 36
    },
    {
      time: '2022-03-28T07:40:32.000Z',
      K: 16
    }
  ]
})
