import { BorderBox13 } from '@jiaminghi/data-view-react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { useState, useEffect, useRef } from 'react'
import { message } from 'antd'

import Ionic from './Charts/ionic'
import { ISoilPrams } from './Charts/options'
import { getSoil_mc1 } from '@/services/soil'

import 'swiper/swiper.scss'
import style from './style.module.scss'
// import { isMethodSignature } from 'typescript'

export default function Soil () {
  const intervalRef = useRef<any>()
  const [ph, setPh] = useState<number>(0)
  const [water, setWater] = useState<number>(0)
  const [nitrogen, setNitrogen] = useState<number>(0)
  const [phosphorus, setPhosphorus] = useState<number>(0)
  const [potassium, setPotassium] = useState<number>(0)
  const [temperature, settem] = useState<number>(0)
  // const [voltage, setvoltage] = useState<number>(0)
  // const voltage2 = (voltage).toFixed(2)
  const [conductivity, setconductivity] = useState<number>(0)
  const [ionoc, setIonoc] = useState<ISoilPrams>({
    date: [''],
    nitrogen: [0],
    phosphorus: [0],
    potassium: [0],
    moisture: [0],
    temperature: [0],
    PH: [0]
  })

  async function getSoilData () {
    const data = await getSoil_mc1()
    // const mockData = await getMockSoil()
    const mockData = await getSoil_mc1()
    if (mockData.stat === 'OK') {
      const date: string[] = []
      const N: number[] = []
      const P: number[] = []
      const K: number[] = []
      const PH:number[] = []
      const moisture:number[] = []
      const temperature:number[] = []
      if (mockData.temperature !== undefined) {
        mockData.temperature.forEach((value) => {
          const time = new Date(value.time)
          date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          N.push(value.temperature)
        })
      }
      if (mockData.moisture !== undefined) {
        mockData.moisture.forEach((value) => {
          P.push(value.moisture)
        })
      }
      if (mockData.PH !== undefined) {
        mockData.PH.forEach((value) => {
          K.push(value.PH)
        })
      }
      // mockData.suan.forEach((value) => {
      //   const time = new Date(value.time)
      //   date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
      //   PH.push(value.PH)
      // })
      // mockData.shi.forEach((value) => {
      //   moisture.push(value.moisture)
      // })
      // mockData.wen.forEach((value) => {
      //   temperature.push(value.temperature)
      // })
      setIonoc({
        date: date,
        nitrogen: N,
        phosphorus: P,
        potassium: K,
        PH: PH,
        moisture: moisture,
        temperature: temperature
      })
    }
    if (data.stat === 'OK') {
      setPh(data.last[0].PH)
      setWater(data.last[0].moisture)
      setNitrogen(data.last[0].N)
      setPhosphorus(data.last[0].P)
      setPotassium(data.last[0].K)
      settem(data.last[0].temperature)
      setconductivity(data.last[0].conductivity)
      // setvoltage(data.last[0].voltage)
      // const date: string[] = []
      // const N: number[] = []
      // const P: number[] = []
      // const K: number[] = []
      // data.dan.forEach((value) => {
      //   const time = new Date(value.time)
      //   date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
      //   N.push(value.N)
      // })
      // data.lin.forEach((value) => {
      //   P.push(value.P)
      // })
      // data.jia.forEach((value) => {
      //   K.push(value.K)
      // })
      // setIonoc({
      //   date: date,
      //   nitrogen: N,
      //   phosphorus: P,
      //   potassium: K
      // })
    } else {
      message.error('获取土壤墒情数据失败！')
    }
  }

  // 第一次挂载的时候执行一次
  useEffect(() => {
    (async function handleData () {
      await getSoilData()
    })()
  }, [])

  // 之后每隔十秒执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getSoilData()
      })()
    }, 180000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className={style.soil_wrap}>
      <BorderBox13 className={style.borderbox13}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-turang_ module'></i>
          <h2 className={style.title}>马三土壤墒情</h2>
        </div>
        <div className={style.charts}>
          <Swiper
            className={style.factor}
            slidesPerView={3}
            observer={true}
            observeParents={true}
            loop={true}
            autoplay={{
              delay: 1000000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-bingbao'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {temperature}
                    <i>°</i>
                  </span>
                  <span className={style.label}>土壤温度</span>
                </p>
              </div>
            </SwiperSlide>
             <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-turangshidu'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {water}
                    <i>%</i>
                  </span>
                  <span className={style.label}>土壤湿度</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-suanjiandu'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {ph}
                  </span>
                  <span className={style.label}>酸碱度</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-turangdanhanliang'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {nitrogen}
                  </span>
                  <span className={style.label}>氮mg/kg</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-turanglinhanliang'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {phosphorus}
                  </span>
                  <span className={style.label}>磷mg/kg</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-turangjiahanliang'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {potassium}
                  </span>
                  <span className={style.label}>钾mg/kg</span>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={style.icons}>
                <span className='icon_circle'>
                  <i className='icont-font icon-yujing'></i>
                </span>
                <p className={style.data}>
                  <span className={style.number}>
                    {conductivity}
                  </span>
                  <span className={style.label}>电导率</span>
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          <Ionic data={ionoc} />
        </div>
      </BorderBox13>
    </div>
  )
}
