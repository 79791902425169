import axios from 'axios'

import { ISoil, IMockSoil } from '@/model/soilType'

// 路由前缀
const PREFIX = '/auto/api/select_info_soil'

/**
 * @: 获取土壤墒情模块数据
 * @param {*}
 * @return {*}
 */
export async function getSoil_ma1 () {
  const data = await axios.get<ISoil>('http://121.5.157.30:3000/api/select_info_soil_myd1_ma1')
  return data.data
}

export async function getSoil_mb3 () {
  const data = await axios.get<ISoil>('http://121.5.157.30:3000/api/select_info_soil_myd2_mb3')
  return data.data
}

export async function getSoil_mc1 () {
  const data = await axios.get<ISoil>('http://121.5.157.30:3000/api/select_info_soil_myd3_mc1')
  return data.data
}

export async function getSoil_md2 () {
  const data = await axios.get<ISoil>('http://121.5.157.30:3000/api/select_info_soil_myd4_md2')
  return data.data
}

export async function getSoil_nan2 () {
  const data = await axios.get<ISoil>('http://121.5.157.30:3000/api/select_info_soil_nmq2_nb2')
  return data.data
}
/**
 * @: 获取土壤墒情模拟数据
 * @param {*}
 * @return {*}
 */
export async function getMockSoil () {
  const data = await axios.get<IMockSoil>(`/local${PREFIX}`)
  return data.data
}
