// import { useContext } from 'react'
import { message } from 'antd'
// import { NavLink } from 'react-router-dom'
import Charts from '@/components/Common/Charts'
// import { geoJson } from './map'
// import { GlobalInfoContext } from '@/store'
import { IMap, mapOption } from './options'
import { EventParams } from '@/types'
// import { centerCamera, camera1 } from '@/utils/constant'
import style from './style.module.scss'
// import mapPath from '!!raw-loader!@/assets/images/map/map.svg'
import mapPath from '!!raw-loader!@/assets/images/map/ma4.svg'
import { BrowserRouter, useHistory } from 'react-router-dom'
// import Map_ma1 from '../Map_ma1'
// import Monitor2 from '../Monitor2'
// import Item from 'antd/lib/list/Item'

export default function Map () {
  const history = useHistory()
  // const { setCamera } = useContext(GlobalInfoContext)
  function handlerClick(params: EventParams<IMap>) {
    if (params.seriesName === 'Coordinate') {
      switch (params.data.name) {
        case 'md1监控': history.push('/ma_4_md1'); break
        case 'md2监控(小)': history.push('/ma_4_md2'); break
        // case 'camera2': setCamera(camera2); break
        // case 'camera3': setCamera(camera3); break
        // case 'camera4': setCamera(camera4); break
        default: history.push('/ma_4_camera4'); break
      }
      message.info(`切换至${params.data.name}监控`)
    }
  }
  return (
    <BrowserRouter>
    <div className={style.map_wrap}>
      <Charts
        key='Map'
        style={{
          width: '100%',
          height: '100%'
        }}
        map={{
          name: 'wuhan',
          svg: mapPath
        }}
        option={mapOption}
        clickCallback={handlerClick}
      />
      {/* <BrowserRouter> */}
      {/* <Route path="ma_1" component={Monitor2}></Route>
      </BrowserRouter> */}
      </div>
      </BrowserRouter>
  )
}
