import axios from 'axios'

import { BaseRes } from '@/model/baseType'
import { ILogin } from '@/model/userType'

// 路由前缀
// const PREFIX = '/auto/api/'
// const PREFIX = '/api/'
/**
 * @: 用户注册
 * @param {string} username
 * @param {string} phone
 * @param {string} password
 * @return {*}
 */
export async function register (username: string, phone: string, password: string) {
  const data = await axios.get<BaseRes>('https://www.duomiai.cn/api/add_userinfo', {
    params: {
      UserName: username,
      Phone: phone,
      PassWord: password
    }
  })
  return data.data
}

/**
 * @: 用户登录
 * @param {string} phone
 * @param {string} password
 * @return {*}
 */
export async function login (phone: string, password: string) {
  const data = await axios.get<BaseRes<ILogin[]>>('https://www.duomiai.cn/api/Check_Login', {
    params: {
      Phone: phone,
      Password: password
    }
  })
  return data.data
}

/**
 * @: 添加访问记录。。。服务端太拉跨了，登录还要弄两个接口
 * @param {string} phone
 * @return {*}
 */
export async function setLogin (phone: string) {
  const data = await axios.get<BaseRes>('https://www.duomiai.cn/api/insert_acessrecord', {
    params: {
      Phone: phone
    }
  })
  return data.data
}

/**
 * @: 更新密码
 * @param {string} phone
 * @param {string} password
 * @return {*}
 */
export async function updatePass (phone: string, password: string) {
  const data = await axios.get<BaseRes>('https://www.duomiai.cn/api/update_Password', {
    params: {
      Phone: phone,
      Password: password
    }
  })
  return data.data
}

/**
 * @: 用户登出
 * @param {string} phone
 * @return {*}
 */
export async function logout (phone: string) {
  const data = await axios.get<BaseRes>('https://www.duomiai.cn/api/update_logout', {
    params: {
      Phone: phone
    }
  })
  return data.data
}
