import { BrowserRouter, Switch } from 'react-router-dom'
import Home from '../Home'
import Login from '../Login'
import Monitors from '../Monitors'
import BigData from '../BigData'
import AuthRoute from '@/components/Common/Router/AuthRoute'
import { RouteItem } from '@/model/componentType'
import React from 'react'

const RouterConfig: RouteItem[] = [{
  path: '/login',
  component: Login
}, {
  path: '/monitors',
  component: Monitors,
  auth: true
}, {
  path: '/bigdata',
  component: BigData,
  auth: true
}, {
  path: '/Home',
  component: Home,
  auth: true
},
// 有路径即可，组件不造成实际影响
// home页马岩墩与楠木桥组件
{
  path: '/ma_1',
  component: Home,
  auth: true
}, {
  path: '/ma_2_mb1',
  component: Home,
  auth: true
}, {
  path: '/ma_2_mb4',
  component: Home,
  auth: true
}, {
  path: '/ma_3',
  component: Home,
  auth: true
}, {
  path: '/ma_4_md1',
  component: Home,
  auth: true
}, {
  path: '/ma_4_md2',
  component: Home,
  auth: true
}, {
  path: '/nan_1',
  component: Home,
  auth: true
}, {
  path: '/nan_2',
  component: Home,
  auth: true
},
// 全屏组件路径
{
  path: '/ma_1max',
  component: Monitors,
  auth: true
}, {
  path: '/mb_1max',
  component: Monitors,
  auth: true
}, {
  path: '/mb_2max',
  component: Monitors,
  auth: true
}, {
  path: '/mc_1max',
  component: Monitors,
  auth: true
}, {
  path: '/md_1max',
  component: Monitors,
  auth: true
}, {
  path: '/md_2max',
  component: Monitors,
  auth: true
}, {
  path: '/na_1max',
  component: Monitors,
  auth: true
}, {
  path: '/nb_1max',
  component: Monitors,
  auth: true
}]
export default function App() {
  return (
    <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <AuthRoute config={RouterConfig} />
      </Switch>
    </BrowserRouter>
    </React.StrictMode>
  )
}
