import * as Echarts from 'echarts'

export const lineColor = new Echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: 'rgba(0, 179, 244, 0.3)'
    },
    {
      offset: 1,
      color: 'rgba(0, 179, 244, 0)'
    }
  ],
  false
)

export const blueColor = new Echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: 'rgba(0,202,149,0.3)'
    },
    {
      offset: 1,
      color: 'rgba(0,202,149,0)'
    }
  ],
  false
)

export const yellowColor = new Echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: 'rgba(255,222,50,0.3)'
    },
    {
      offset: 1,
      color: 'rgba(255,222,50,0.3)'
    }
  ],
  false
)

export const mapColorList = [
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#64fbc5'
    },
    {
      offset: 1,
      color: '#018ace'
    }
  ]),
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#64fbc5'
    },
    {
      offset: 1,
      color: '#018ace'
    }
  ]),
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#168e6d'
    },
    {
      offset: 1,
      color: '#c78d7b'
    }
  ]),
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#61c0f1'
    },
    {
      offset: 1,
      color: '#6f2eb6'
    }
  ]),
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#168e6d'
    },
    {
      offset: 1,
      color: '#c78d7b'
    }
  ]),
  new Echarts.graphic.LinearGradient(1, 0, 0, 0, [
    {
      offset: 0,
      color: '#61c0f1'
    },
    {
      offset: 1,
      color: '#6f2eb6'
    }
  ])
]
