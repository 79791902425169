import { ECOption } from '@/types'
// import { lineColor, blueColor } from '@/utils/charts'
// 趋势参数
export interface IDataPrams {
  count: number[]
  NAME: string[]
  /**
   * 日期
   */
  date: string[]
  /**
   * 一个月蚊虫数量
   */
  orderByTime: number[]
  /**
   * 该月蚊虫类型与数量
   */
  // oderByName: IByName[]
}

export const MosOption = (params: IDataPrams): ECOption => ({
  title: {
    text: '蚊虫数据',
    show: true,
    textStyle: {
      fontSize: 12,
      color: '#bcdcff'
    }
  },
  legend: {
    show: true,
    top: 0,
    left: '50%',
    itemHeight: 12,
    textStyle: {
      color: '#c0c9d2'
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      lineStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: 'rgba(0, 255, 233,0)'
            },
            {
              offset: 0.5,
              color: 'rgba(255, 255, 255,1)'
            },
            {
              offset: 1,
              color: 'rgba(0, 255, 233,0)'
            }
          ],
          global: false
        }
      }
    }
  },
  grid: {
    top: '30%',
    left: '10%',
    right: '10%',
    bottom: '15%'
  },
  xAxis: [{
    // name: 'temperature',
    type: 'category',
    position: 'top',
    axisLine: {
      show: false
    },
    splitArea: {
      areaStyle: {
        color: ['#f00']
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 'auto',
      color: '#d1e6eb'
    },
    splitLine: {
      show: false
    },
    boundaryGap: true,
    data: params.date
  }, {
    // name: 'temperature',
    type: 'category',
    axisLine: {
      show: false
    },
    splitArea: {
      areaStyle: {
        color: ['#f00']
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      interval: 0,
      color: '#d1e6eb'
    },
    splitLine: {
      show: false
    },
    boundaryGap: true,
    data: params.NAME
  }],
  yAxis: [{
    type: 'value',
    // name: '数目',
    position: 'left',
    nameTextStyle: {
      color: '#91CC75'
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: 'rgba(255, 255, 255, 0.1)'
      }
    },
    axisLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      margin: 10,
      color: '#d1e6eb'
    }
  }],
  dataZoom: [
    {
      show: true,
      start: 70,
      end: 100,
      height: '5%',
      top: '92%'
    },
    {
      type: 'inside',
      start: 94,
      end: 100
    }
  ],
  series: [{
    name: '蚊虫数量',
    type: 'line',
    xAxisIndex: 0,
    yAxisIndex: 0,
    // barWidth: 20,
    label: {
      position: 'top',
      show: false,
      color: '#d1e6eb'
    },
    data: params.orderByTime,
    itemStyle: {
      color: 'rgba(210,210,0,0.8)'
    }
  }, {
    name: '蚊虫种类数目',
    type: 'bar',
    xAxisIndex: 1,
    yAxisIndex: 0,
    barWidth: 'auto',
    label: {
      position: 'top',
      fontSize: 'auto',
      show: true,
      color: '#00ca95'
    },
    data: params.count,
    itemStyle: {
      color: '#00ca95'
    }
  }]
}
)

// export const MosOption1 = (params: IDataPrams): ECOption => ({
//   title: {
//     text: '30天马岩墩',
//     right: 1,
//     bottom: 0.5,
//     show: true,
//     textStyle: {
//       fontSize: 11,
//       color: '#999999'
//     }
//   },
//   tooltip: {
//     trigger: 'item',
//     formatter: '{a} <br/>{b}: {c} ({d}%)',
//     textStyle: {
//       fontSize: 14
//     }
//   },
//   legend: {
//     orient: 'vertical',
//     right: 'right',
//     itemWidth: 10,
//     itemHeight: 8,
//     itemGap: 2,
//     textStyle: {
//       color: '#fff',
//       fontSize: 10
//     }
//   },
//   series: [
//     {
//       name: '数目',
//       type: 'pie',
//       radius: ['40%', '70%'],
//       center: ['50%', '50%'],
//       data: params.count,
//       emphasis: {
//         itemStyle: {
//           shadowBlur: 10,
//           shadowOffsetX: 0,
//           shadowColor: 'rgba(0, 0, 0, 0.5)'
//         }
//       },
//       label: {
//         show: true
//         // formatter(param) {
//         // correct the percentage
//         // return param.name + ' (' + param.percent * 2 + '%)';
//       }
//     }
//   ]
// })

// export const MosOption1 = (params: IDataPrams): ECOption => ({
//   title: {
//     text: '蚊虫数据',
//     show: true,
//     textStyle: {
//       fontSize: 12,
//       color: '#bcdcff'
//     }
//   },
//   legend: {
//     show: true,
//     top: 0,
//     left: '50%',
//     itemHeight: 12,
//     textStyle: {
//       color: '#c0c9d2'
//     }
//   },
//   tooltip: {
//     trigger: 'axis',
//     axisPointer: {
//       lineStyle: {
//         color: {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             {
//               offset: 0,
//               color: 'rgba(0, 255, 233,0)'
//             },
//             {
//               offset: 0.5,
//               color: 'rgba(255, 255, 255,1)'
//             },
//             {
//               offset: 1,
//               color: 'rgba(0, 255, 233,0)'
//             }
//           ],
//           global: false
//         }
//       }
//     }
//   },
//   grid: {
//     top: '30%',
//     left: '10%',
//     right: '10%',
//     bottom: '15%'
//   },
//   xAxis: [{
//     // name: 'temperature',
//     type: 'category',
//     axisLine: {
//       show: false
//     },
//     splitArea: {
//       areaStyle: {
//         color: ['#f00']
//       }
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       interval: 'auto',
//       color: '#d1e6eb'
//     },
//     splitLine: {
//       show: false
//     },
//     boundaryGap: true,
//     data: params.name
//   }],
//   yAxis: [{
//     type: 'value',
//     name: '数目',
//     position: 'left',
//     nameTextStyle: {
//       color: '#91CC75'
//     },
//     splitLine: {
//       show: true,
//       lineStyle: {
//         color: 'rgba(255, 255, 255, 0.1)'
//       }
//     },
//     axisLine: {
//       show: false
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       margin: 10,
//       color: '#d1e6eb'
//     }
//   }],
//   series: [{
//     name: '今日蚊虫数量',
//     type: 'bar',
//     xAxisIndex: 0,
//     yAxisIndex: 0,
//     barWidth: 20,
//     label: {
//       position: 'top',
//       show: true,
//       color: '#d1e6eb'
//     },
//     data: params.count,
//     itemStyle: {
//       color: 'rgba(210,210,0,0.8)'
//     }
//   }]
// })

// export const MosOption1 = (params: IDataPrams): ECOption => ({
//   title: {
//     text: '蚊虫数据',
//     show: true,
//     textStyle: {
//       fontSize: 12,
//       color: '#bcdcff'
//     }
//   },
//   legend: {
//     show: true,
//     top: 0,
//     left: '50%',
//     itemHeight: 12,
//     textStyle: {
//       color: '#c0c9d2'
//     }
//   },
//   tooltip: {
//     trigger: 'axis',
//     axisPointer: {
//       lineStyle: {
//         color: {
//           type: 'linear',
//           x: 0,
//           y: 0,
//           x2: 0,
//           y2: 1,
//           colorStops: [
//             {
//               offset: 0,
//               color: 'rgba(0, 255, 233,0)'
//             },
//             {
//               offset: 0.5,
//               color: 'rgba(255, 255, 255,1)'
//             },
//             {
//               offset: 1,
//               color: 'rgba(0, 255, 233,0)'
//             }
//           ],
//           global: false
//         }
//       }
//     }
//   },
//   grid: {
//     top: '30%',
//     left: '10%',
//     right: '10%',
//     bottom: '15%'
//   },
//   xAxis: [{
//     // name: 'temperature',
//     type: 'category',
//     axisLine: {
//       show: false
//     },
//     splitArea: {
//       areaStyle: {
//         color: ['#f00']
//       }
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       interval: 'auto',
//       color: '#d1e6eb'
//     },
//     splitLine: {
//       show: false
//     },
//     boundaryGap: true,
//     data: params.name
//   }],
//   yAxis: [{
//     type: 'value',
//     name: '数目',
//     position: 'left',
//     nameTextStyle: {
//       color: '#91CC75'
//     },
//     splitLine: {
//       show: true,
//       lineStyle: {
//         color: 'rgba(255, 255, 255, 0.1)'
//       }
//     },
//     axisLine: {
//       show: false
//     },
//     axisTick: {
//       show: false
//     },
//     axisLabel: {
//       margin: 10,
//       color: '#d1e6eb'
//     }
//   }],
//   series: [{
//     name: '今日蚊虫数量',
//     type: 'bar',
//     xAxisIndex: 0,
//     yAxisIndex: 0,
//     barWidth: 20,
//     label: {
//       position: 'top',
//       show: true,
//       color: '#d1e6eb'
//     },
//     data: params.count,
//     itemStyle: {
//       color: 'rgba(210,210,0,0.8)'
//     }
//   }]
// })
