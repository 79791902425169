import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './style.module.scss'

export default class index extends Component {
  render() {
    return (
        <div className={styles.row_wrap}>
        <NavLink className={styles.iconsuse} to="/ma_1">马岩墩一</NavLink>
        <NavLink className={styles.icons} to="/ma_2_mb1">马岩墩二</NavLink>
        <NavLink className={styles.icons} to="/ma_3">马岩墩三</NavLink>
        <NavLink className={styles.icons} to="/ma_4_md1">马岩墩四</NavLink>
        <NavLink className={styles.icons} to="/nan_1">楠木桥一</NavLink>
        <NavLink className={styles.icons} to="/nan_2">楠木桥二</NavLink>
    </div>
    )
  }
}
