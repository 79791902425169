import * as crypto from 'crypto'

const KEY = '37725295ea78b626'
const IV = 'efcf77768be478cb'

/**
 * @: 对称加密
 * @param {string} value
 * @return {*}
 */
export function encode(value: string) {
  let sign = ''
  const cipher = crypto.createCipheriv('aes-128-cbc', KEY, IV) // createCipher在10.0.0已被废弃
  sign += cipher.update(value, 'utf8', 'hex')
  sign += cipher.final('hex')
  return sign
}

/**
 * @: 对称解密
 * @param {string} value
 * @return {*}
 */
export function decode(value: string) {
  let src = ''
  const cipher = crypto.createDecipheriv('aes-128-cbc', KEY, IV)
  src += cipher.update(value, 'hex', 'utf8')
  src += cipher.final('utf8')
  return src
}
