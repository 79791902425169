import { BorderBox12 } from '@jiaminghi/data-view-react'
import { useState, useEffect, useRef } from 'react'
import Temperature from './Charts/mosquito'
import { IDataPrams } from './Charts/options_mosquito'
import { getMosquitoData } from '@/services/bigdata'
import MosquitoPicData from '@/components/Home/MosquitoPicData'
import 'swiper/swiper.scss'
import style from './style.module.scss'
// import { IByName } from '@/model/bigdataType'
// import { IByName } from '@/model/bigdataType'

export default function BigData () {
  const intervalRef = useRef<any>()
  // 蚊虫
  const [ionoc_mos, setIonoc_mos] = useState<IDataPrams>({
    count: [0],
    NAME: [''],
    date: [''],
    orderByTime: [0]
    // oderByName: [{ count: 0, name: '' }]
  })

  async function getBigData () {
    // 土壤和光照
    const MosData = await getMosquitoData()
    if (MosData.status === 'success') {
      const count: number[] = []
      const NAME: string[] = []
      const date: string[] = []
      const orderByTime:number[] = []
      // const oderByName: IByName[] = []
      if (MosData !== undefined) {
        MosData.orderByTime.forEach((value) => {
          const time = new Date(value.date)
          date.push(`${(time.getMonth() + 1).toString().padStart(2, '0')}-${time.getDate().toString().padStart(2, '0')}`)
          orderByTime.push(value.count)
        })
      }
      if (MosData.orderByName !== undefined) {
        MosData.orderByName.forEach((value) => {
          NAME.push(value.NAME)
          count.push(value.count)
        })
        // for (let i = 0; i <= 5; i++) {
        //   oderByName.push({ count: count[i], name: name[i] })
        // }
      }
      // if (MosData !== undefined) {
      // }
      setIonoc_mos({
        count: count,
        NAME: NAME,
        date: date,
        orderByTime: orderByTime
        // oderByName: oderByName
      })
    }
  }

  // 第一次挂载的时候执行一次
  useEffect(() => {
    (async function handleData () {
      await getBigData()
    })()
  }, [])

  // 之后每隔一小时执行一次
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      (async function handleData () {
        await getBigData()
      })()
    }, 3600000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className={style.soil_wrap}>
      <BorderBox12 className={style.borderbox12}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-turang_ module'></i>
          <h2 className={style.title}>蚊虫大数据</h2>
        </div>
        <div className={style.charts2}>
          <MosquitoPicData/>
        </div>
        {/* <div className={style.charts2}>
        </div> */}
        <div className={style.charts1}>
          <Temperature data2={ionoc_mos} />
        </div>
      </BorderBox12>
    </div>
  )
}
