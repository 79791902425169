import { Switch } from 'antd'
import React from 'react'

const Switchbutton: React.FC = () => (
  <>
    <Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked />
    <br />
  </>
)

export default Switchbutton
