import { BorderBox13, ScrollBoard } from '@jiaminghi/data-view-react'

import style from './style.module.scss'

export default function Prewarning () {
  var date = new Date()
  const time = date.toLocaleDateString()
  const config = {
    // 表头高度
    headerHeight: 30,
    // 表头背景色
    headerBGC: '#443dc5',
    // 奇数行背景色
    oddRowBGC: '#09184F',
    // 偶数行背景色
    evenRowBGC: '#070C34',
    // 行号
    index: true,
    // 行号表头
    indexHeader: '序号',
    // 宽度
    columnWidth: [80, 170],
    // 对其方式
    align: ['center'],
    // 表行数
    rowNum: 3,
    // 数据
    header: ['内容', '时间'],
    // data: [[
    //   '暂无', '暂无'
    // ]]
    data: [[
      '雨量显示异常', `${time}`
    ], [
      '暂无数据', `${time}`
    ], [
      '暂无数据', `${time}`
    ], [
      '暂无数据', `${time}`
    ]]
  }

  return (
    <div className={style.scoll_wrap}>
      <BorderBox13 className={style.borderbox13}>
        <div className={style.title_wrap}>
          <i className='icont-font icon-yujing module'></i>
          <h2 className={style.title}>预警信息</h2>
        </div>
        <div className={style.bord_wrap}>
          <ScrollBoard
            config={config}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </BorderBox13>
    </div>
  )
}
