import { FC } from 'react'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import Header_mo from '@/components/Home/Header_mo'
import { Route } from 'react-router-dom'
import Monitor1 from '@/components/Home/All of Monitors/ma1'
import Monitor2 from '@/components/Home/All of Monitors/mb1'
import Monitor3 from '@/components/Home/All of Monitors/mb4'
import Monitor4 from '@/components/Home/All of Monitors/mc1'
import Monitor5 from '@/components/Home/All of Monitors/md1'
import Monitor6 from '@/components/Home/All of Monitors/md2'
import Monitor7 from '@/components/Home/All of Monitors/na1'
import Monitor8 from '@/components/Home/All of Monitors/nb1'
import Monitorcopy1 from '@/components/Home/All of Monitors/ma1 fullscreen'
import Monitorcopy2 from '@/components/Home/All of Monitors/mb1 fullscreen'
import Monitorcopy3 from '@/components/Home/All of Monitors/mb4 fullscreen'
import Monitorcopy4 from '@/components/Home/All of Monitors/mc1 fullscreen'
import Monitorcopy5 from '@/components/Home/All of Monitors/md1 fullscreen'
import Monitorcopy6 from '@/components/Home/All of Monitors/md2 fullscreen'
import Monitorcopy7 from '@/components/Home/All of Monitors/na1 fullscreen'
import Monitorcopy8 from '@/components/Home/All of Monitors/nb1 fullscreen'
import styles from './style.module.scss'

const Home: FC = () => {
  return (
    <FullScreenContainer>
      <div className={styles.container}>
        <Header_mo/>
        <div className={styles.content_wrap}>
          <div className={styles.monitor_comtent}>
          <Route path='/ma_1max' component={Monitorcopy1}/>
          <Route path='/mb_1max' component={Monitorcopy2}/>
          <Route path='/mb_2max' component={Monitorcopy3}/>
          <Route path='/mc_1max' component={Monitorcopy4}/>
          <Route path='/md_1max' component={Monitorcopy5}/>
          <Route path='/md_2max' component={Monitorcopy6}/>
          <Route path='/na_1max' component={Monitorcopy7}/>
          <Route path='/nb_1max' component={Monitorcopy8}/>
          <div className={styles.left1_content}>
          <Route path='/monitors' component={Monitor1}/>
          <Route path='/monitors' component={Monitor5}/>
          </div>
          <div className={styles.left2_content}>
          <Route path='/monitors' component={Monitor2}/>
          <Route path='/monitors' component={Monitor6}/>
          </div>
          <div className={styles.left3_content}>
          <Route path='/monitors' component={Monitor3}/>
          <Route path='/monitors' component={Monitor7}/>
          </div>
          <div className={styles.left4_content}>
          <Route path='/monitors' component={Monitor4}/>
          <Route path='/monitors' component={Monitor8}/>
          </div>
          </div>
        </div>
      </div>
    </FullScreenContainer>
  )
}

export default Home
