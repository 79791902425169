import { useRef, useEffect } from 'react'
import * as Echarts from 'echarts'

interface IProps<T = undefined> {
  key: string
  option: Record<string, unknown> | null
  style?: {
    width: string
    height: string
  }
  map?: {
    name: string
    svg?: string
    geoJson?: object
  }
  className?: string
  onRender?: (instance: any) => void
  clickCallback?(params: T): void
}

export default function Charts<T = undefined> (props: IProps<T>) {
  // 挂载节点
  const chartDom = useRef<any>()
  const instance = useRef<any>()
  // 加载状态
  function showLoading(_instance: {
    showLoading: (
      arg0: string,
      arg1: {
        text: string;
        color: string;
        textColor: string;
        maskColor: string;
        zlevel: number;
      }
    ) => void;
  }): void {
    _instance.showLoading('default', {
      text: '',
      color: '#c23531',
      textColor: '#000000',
      maskColor: 'rgba(255, 255, 255, 0.8)',
      zlevel: 0
    })
  }

  // 扩展方法 - 获取实例对象
  // const getInstance = () => instance.current

  // 生命钩子函数
  useEffect(() => {
    // 获取实例对象
    instance.current =
      Echarts.init(chartDom.current as HTMLElement)

    // 注册地图
    if (props.map?.geoJson) {
      Echarts.registerMap(props.map?.name, props.map.geoJson)
    } else if (props.map && props.map?.svg) {
      Echarts.registerMap(props.map?.name, { svg: props.map.svg })
    }

    // 大小自适应
    const resize = (): any => instance.current.resize({
      width: 'auto',
      height: 'auto'
    })
    window.addEventListener('resize', resize())

    // 默认加载状态
    showLoading(instance.current)

    // 销毁并清除状态
    return (): void => {
      Echarts.dispose(instance.current)
      window.removeEventListener('resize', resize())
    }
  }, [chartDom])

  useEffect(() => {
    // 默认加载状态
    showLoading(instance.current)

    // 渲染图表
    if (props.option) {
      if (instance.current) {
        instance.current.hideLoading()
        instance.current.setOption(props.option)
        // 点击事件
        if (typeof props.clickCallback === 'function') {
          instance.current.on('click', props.clickCallback)
          // 获取地图的svg点的坐标
          // instance.current.getZr().on('click', function (params: any) {
          //   const pixelPoint = [params.offsetX, params.offsetY]
          //   const dataPoint = instance.current.convertFromPixel({ geoIndex: 0 }, pixelPoint)
          //   // 在 SVG 上点击时，坐标会被打印。
          //   // 这些坐标可以在 `series.data` 里使用。
          //   console.log(dataPoint)
          // })
        }
      }
    }

    // 回调函数返回实例
    if (props.onRender) props.onRender(instance.current)
  }, [props])

  // 返回组件
  return (
    <div ref={chartDom} style={props.style} className={props.className}></div>
  )
}
