import { FC } from 'react'
import { FullScreenContainer } from '@jiaminghi/data-view-react'
import Header_big from '@/components/Home/Header_big'
import { Route } from 'react-router-dom'
import WeatherData from '@/components/Home/WeatherData/index'
import MosqutioData from '@/components/Home/MosquitoData/index'
import QualityControl from '@/components/Home/QualityControl/index'
import styles from './style.module.scss'

const Home: FC = () => {
  return (
    <FullScreenContainer>
      <div className={styles.container}>
        <Header_big/>
        <div className={styles.content_wrap}>
          <div className={styles.monitor_comtent}>
          <div className={styles.left1_content}>
          <Route path='/bigdata' component={WeatherData}/>
          </div>
          <div className={styles.left2_content}>
          <Route path='/bigdata' component={QualityControl}/>
          <Route path='/bigdata' component={MosqutioData}/>
          </div>
          </div>
        </div>
      </div>
    </FullScreenContainer>
  )
}

export default Home
