import { Menu, Dropdown, message, Modal, Form, Input } from 'antd'
import { DownOutlined, UserOutlined } from '@ant-design/icons'
import { useHistory, NavLink } from 'react-router-dom'
import { useState, useContext } from 'react'
import { Decoration8, Decoration10 } from '@jiaminghi/data-view-react'
// import { Link } from 'react-router-dom'
import { GlobalInfoContext } from '@/store'
import { logout, updatePass } from '@/services/user'
import { getStorage, delStorage } from '@/utils'
import '@/assets/styles/icon.scss'
import styles from './style.module.scss'

function Header () {
  const [form] = Form.useForm()
  const { userName, setUserName } = useContext(GlobalInfoContext)
  const [isModalVisible, setIsModalVisibel] = useState<boolean>(false)
  const history = useHistory()
  async function handleLogout () {
    const phone = getStorage('phone') || ''
    const data = await logout(phone)
    if (data.stat === 'OK') {
      setUserName('')
      message.success('已退出登录')
      delStorage('username')
      delStorage('phone')
      delStorage('password')
      history.push('/login')
    } else {
      message.error(data.message)
    }
  }
  async function handleUpdate () {
    const phone = getStorage('phone') || ''
    const password = form.getFieldValue('password')
    const data = await updatePass(phone, password)
    if (data.stat === 'OK') {
      console.log('password', password)
      form.resetFields()
      message.success('修改成功！')
      setIsModalVisibel(false)
    } else {
      message.error(data.message)
    }
  }
  function cancel () {
    setIsModalVisibel(false)
  }
  return (
    <header className={styles.wrap}>
      <Decoration10 className={styles.decoration10} />
      <div className={styles.title_wrap}>
        <Decoration8
          className={styles.decoration8}
          color={['#568aea', '#000000']}
        />
        <div className={styles.title}>
          <h1 className={styles.text}>监控管理系统</h1>
          {/* <Decoration6
            className={`${styles.bottom} ${styles.decoration6}`}
            reverse={true}
            color={['#50e3c2', '#67a1e5']}
          /> */}
        </div>
        <Decoration8
          className={styles.decoration8}
          reverse={true}
          color={['#568aea', '#000000']}
        />
      </div>
      <Decoration10 className={styles.decoration10_reverse} />
      <NavLink className={styles.userinfo2} to="/">
        返回茶园
      </NavLink>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key='modify'
              onClick={() => setIsModalVisibel(true)}
            >
              修改密码
            </Menu.Item>
            <Menu.Item
              key='logout'
              onClick={() => handleLogout()}
            >
              退出
            </Menu.Item>
          </Menu>
        }
      >
        <div className={styles.userinfo}>
          <p><UserOutlined /></p>
          <p>{userName}</p>
          <p><DownOutlined /></p>
        </div>
      </Dropdown>
      <Modal
        transitionName=''
        maskTransitionName=''
        keyboard={false}
        maskClosable={false}
        centered
        title={'修改密码'}
        visible={isModalVisible}
        okText={'提交'}
        cancelText={'取消'}
        onOk={() => handleUpdate()}
        onCancel={() => cancel()}
      >
        <Form
          className='ant-advanced-search-form'
          form={form}
        >
          <Form.Item
            key='password'
            name={'password'}
            label='新密码'
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input type="password" placeholder="请输入密码" onPressEnter={() => handleUpdate()} />
          </Form.Item>
        </Form>
      </Modal>
      {/* <div className={styles.weather_wrap}>
        <i className='icont-font icon-duoyun weather'></i>
        <span className={styles.temperature}>20°</span>
      </div> */}
      {/* <div className={styles.menu_wrap}>
        <BorderBox12 className={styles.borderbox12}>
          <span>导航菜单</span>
        </BorderBox12>
      </div> */}
    </header>
  )
}

export default Header
