import Charts from '@/components/Common/Charts'
import { MosOption, IDataPrams } from './options_mosquito'
import styles from './style.module.scss'

interface IProps {
  data2: IDataPrams
}

export default function Temperature (props: IProps) {
  return (
    <>
      <div className={styles.tem_chart}>
        <Charts
          key='temperature'
          style={{
            width: '80%',
            height: '320%'
          }}
          option={MosOption(props.data2)}
        />
      </div>
      {/* <div className={styles.tem_chart}>
        <Charts
          key='temperature'
          style={{
            width: '80%',
            height: '300%'
          }}
          option={MosOption1(props.data2)}
        />
      </div> */}
    </>
  )
}
