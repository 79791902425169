import React from 'react'
import ReactDOM from 'react-dom'
import '@/assets/styles/global.scss'
import App from './pages/App'
import GlobalInfoContextProvider from './store/provider'
import '@/mock/air'
import '@/mock/soil'

ReactDOM.render(
  <React.StrictMode>
    <GlobalInfoContextProvider>
      <App />
    </GlobalInfoContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
